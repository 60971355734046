.ScheduleTimePicker {
  border-top: solid 1px #FFFFFF;
  text-align: center;
}

.ScheduleTimePicker .btn-next {
  padding: 12px 10px;
}

.ScheduleTimePicker .btn-next > span {
  width: 100%;
  display: flex;
}

.ScheduleTimePicker .btn-next .Next {
  margin-right: 0;
}

.ScheduleTimePickerOption {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 80%;
  padding: 12px 10px;
  border: solid 1px #91B698;
  border-radius: 4px;
  text-align: center;
  font-weight: normal;
  height: 46px;
}

.ScheduleTimePickerOption, .ScheduleTimePickerDate {
  max-width: 400px;
}

.ScheduleTimePickerOption .indicator {
  height: 15px;
  width: 15px;
  border: solid 1px #91B698;
  border-radius: 50%;
  display: block;
  margin-right: 10px;
  float: left;
  position: relative;
  top: 2.5px;
}

.ScheduleTimePickerOption .content {
  position: absolute;
  left: 0;
  right: 0;
}

.ScheduleTimePickerOption.active {
  background-color: #91B698;
  color: #FFFFFF;
}

.ScheduleTimePickerOption.active .indicator {
  background-color: #3E6144;
  border-color: #3E6144;
}

.ScheduleTimePickerOption input {
  display: none;
}

.OptionSeparator {
  font-style: italic;
  margin: 15px 0;
  display: block;
}

.ScheduleTimePicker .Header {
  margin: 15px 0;
}

.ScheduleTimePickerDate {
  display: flex;
  position: relative;
  width: 80%;
  margin: 15px auto 0;
}

.ScheduleTimePickerDate .SelectWrapper {
  flex: 1;
}

.ScheduleTimePickerDate .dropdown #pickup-date {
  width: 100%;
}

.ScheduleTimePickerDate .TimePicker {
  display: flex;
}

.ScheduleTimePickerDate .TimePicker .SelectWrapper::after {
  display: none;
}

.ScheduleTimePickerDate .TimePicker select {
  background: transparent;
  appearance: none;
  padding: 0 20px;
  line-height: 46px;
  height: 100%;
  border-color: #91B698;
  margin-left: 10px;
  border-radius: 4px;
}

.ScheduleTimePicker .btn-next {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
}


/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .ScheduleTimePicker {
    padding-bottom: calc(47px + 20px);
  }

  .ScheduleTimePickerOption, .ScheduleTimePickerDate {
    max-width: auto;
  }

  .ScheduleTimePicker .btn-next {
    position: fixed;
  }

  .ScheduleTimePicker .ScheduleTimePickerDate .dropdown-menu {
    top: initial;
    bottom: 100%;
  }
}
