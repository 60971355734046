.CustomizeLeft {
  width: 46vw;
  height: calc(100vh - 45px);
  float: left;
  position: relative;
}
.CustomizeLeft .img-menud {
  background-color: #ffffff;
  width: 100%;
  height: calc(100vh - 331px);
  min-height: 250px;
  text-align: center;
  overflow-x: hidden;
}

.CustomizeLeft .img-menud img {
  object-fit: cover;
  width: auto;
  height: 100%;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}


/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .CustomizeLeft {
    width: 100%;
    height: auto;
    float: none;
  }
  .CustomizeLeft .img-menud {
    height: 31.5vh;
    min-height: 50px;
    display: block;
  }
}
