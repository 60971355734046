.Image-desktop {
  display: initial;
}
.Image-mobile {
  display: none;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .Image-desktop {
    display: none;
  }
  .Image-mobile {
    display: initial;
  }
}
