.img-home {
  height: 68vh;
  min-height: 415px;
}
.img-home img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  min-height: 415px;
}
.img-home h2 {
  font-size: 4.4em;
  line-height: 1.3em;
  position: relative;
  top: -280px;
  text-shadow: 0px 1px 3px rgba(0,0,0,0.4);
  width: 3em;
  text-align: center;
  margin: auto;
}
.content-home {
  width: 32vw;
  min-width: 300px;
  height: 32vh;
  margin: auto;
  padding-top: 30px;
}
.content-mobile {
  text-align: center;
  padding-top: 6px;
  max-width: 90%;
  margin: auto;
}
.footer {
  text-align: center;
  border-top: 1px solid #ffffff;
  padding-top: 25px;
  clear: both;
}
.footer ul li {
  display: inline-block;
  padding: 10px;
}
.footer ul li a {
  color: #3c6142;
}
.footer ul li a:hover {
  text-decoration: underline;
}
.footer ul.social-link li a{
  font-size: 1.9em;
  padding: 10px;
}
.footer ul.cpr-msg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer ul.cpr-msg li {
  color: #95989A;
  padding: 3px;
  vertical-align: baseline;
}
.footer ul.cpr-msg li span {
  padding-right: 4px;
}
.footer ul.cpr-msg li img {
  height: 12px;
  width: auto;
  position: relative;
  top: -3px;
}
.footer ul.cpr-msg p {
  margin: 0;
}
.index-user-img {
  width: 63vw;
  height: calc(100vh - 45px);
  border-right: 1px solid #ffffff;
  float: left;
  position: relative;
  text-align: center;
}
.index-user-img > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
}
.index-user-img h2 {
  position: absolute;
  top: 45%;
  text-shadow: 0px 1px 3px rgba(0,0,0,0.4);
  color: #ffffff;
  width: 100%;
  text-align: center;
}
.index-user-img .TitledMenuSection {
  overflow: auto;
}
.user-container {
  width: calc(100% - 63vw);
  float: right;
  margin: auto;
  height: calc(100vh - 45px);
  position: relative;
  overflow-y: auto;
}
.user-container .user-top {
  display: block;
  vertical-align: bottom;
  height: 40vh;
  padding-top: calc((50vh - 260px) / 2);
}
.user-container table.separator {
  text-align: center;
  display: table;
  width: 100%;
  height: 30px;
  position: relative;
}

.user-container .ContentSeparator p {
  font-size: 1em;
  color: inherit;
}

.user-container table.separator td {
  display: table-cell;
  color: #3c6142;
  vertical-align: middle  ;
}
.user-container table.separator td:first-child,
.user-container table.separator td:last-child {
  width: 50%;
  position: relative;

}
.user-container table.separator td:first-child>div,
.user-container table.separator td:last-child>div {
  height: 1px;
  border-top: 1px solid #ffffff;
}

.user-container table.separator td:nth-child(2) {
  white-space: nowrap;
  padding: 10px 10px 0 10px;
}
.user-container .user-location-list {
  padding: 0 20px;

}
.user-container .user-location {
  position: absolute;
  bottom: 0;
  padding-bottom: 30px;
  right: 0;
  left: 0;
}
.user-container .user-order {
  width: 100%;
  text-align: center;
  color: #3c6242;
  border-top: 1px solid #ffffff;
  padding-top: 14px;
  padding-bottom: 10px;
}
.user-container .LandingCurrentOrder {
  width: 94%;
  max-width: 387px;
  margin: 0 auto 12px auto;
  position: relative;

}

.user-container .LandingCurrentOrder ul.order-header {
  background-color: #3c6142;
  position: relative;
  padding-bottom: 3.1em;
  width: 100%;
}
.user-container .LandingCurrentOrder ul.order-header li {
  display: inline-block;
  color: #ffffff;
}
.user-container .LandingCurrentOrder ul.order-header li:first-child {
  text-align: left;
  position: absolute;
  left: 10px;
  top: 5px;
}
.user-container .LandingCurrentOrder ul.order-header li h5 {
  font-size: 1em;
}
.user-container .LandingCurrentOrder ul.order-header li h5 span img {
  width: 22px;
  height: 22px;
  margin-right: 5px;
  position: relative;
  top: -2px;
}

.user-container .LandingCurrentOrder ul.order-header li:nth-child(2) {
  font-size: 0.9em;
  position: absolute;
  right: 14px;
  top: 34%;
}

.user-container .LandingCurrentOrder ul.order-header li:nth-child(2) p {
  padding-bottom: 0;
  margin-bottom: 0;
}
.user-container .LandingCurrentOrder ul.order-header li:nth-child(2) .fromNow {
  border-left: 1px solid #92D27F;
  padding-left: 8px;
  margin-left: 6px;

}
.user-container .LandingCurrentOrder .order-body {
  background-color: #F2EDE9;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
}
.user-container .LandingCurrentOrder .order-body {
  text-align: left;
  padding: 12px 0;
}
.user-container .LandingCurrentOrder .order-body ul li {
  display: inline-block;
  padding-bottom: 3px;
  vertical-align: text-top;
}
.user-container .LandingCurrentOrder .order-body ul li:first-child {
  width: 78%;
  padding-left: 14px;
}
.user-container .LandingCurrentOrder .order-body ul li:nth-child(2) {
  width: 8%;
  text-align: center;
}
.user-container .LandingCurrentOrder .order-body ul li:nth-child(3) {
  width: 14%;
  text-align: right;
  padding-right: 12px;
}
.user-container .LandingCurrentOrder .order-body p.total {
  width: 100%;
  border-top: 1px solid #ffffff;
  padding-top: 8px;
  margin-top: 2px;
  text-align: right;
  padding-right: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.user-container .LandingCurrentOrder .order-body p.total span.label {
  color: #7D7769;
  font-weight: 400 !important;
  margin-right: 6px;
}
.user-order > a {
  color: inherit;
}

.index-user-img .TitledMenuSection {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 black;
  max-height: 100%;
  background-color: rgba(60, 97, 66, 0.4);
  padding-top: 10px;
}

.index-user-img .TitledMenuSection .SectionSeparator {
  color: #FFFFFF !important;
}

.index-user-img .TitledMenuSection .SectionSeparator .line {
  border-top: 1px solid #FFF;
}

.index-user-img .TitledMenuSection .section {
  padding: 0 10px;
}

.index-user-img .TitledMenuSection .MenuItem {
  width: calc(20% - 10px);
  max-width: 160px;
  min-width: 144px;
}

.index-user-img .TitledMenuSection .MenuItem .menu-image img {
  height: auto;
  max-height: 160px;
}

.TitledMenuSection .section {
  margin-top: 10px;
}

/*--- Only Desktop ---*/
@media (min-width: 1201px) {
  .img-home {
    margin-top: -54px;
  }
}

/*--- Small Desktop ---*/
@media (min-width: 991px) and (max-width: 1200px) {
  .img-home, .img-home img {
    height : 60vh;
  }
  .content-home {
    padding-top: 30px;
    height: calc(40vh - 45px);
  }
}
/*--- Tablet and Mobile ---*/
@media (max-width: 991px) {
  .index-user-img {
    width: 100%;
    height: 16vh;
    border-right: none;
    float: none;
  }
  .index-user-img h2 {
    font-size: 2.6em;
    top: 32%;
  }
  .user-container {
    width: 100%;
    float: none;;
    height: auto;
    min-height: calc(84vh - 45px);
  }
  .user-container .user-top {
    display: block;
    vertical-align: bottom;
    height: auto;
    padding-top: 30px;
    padding-bottom: 25px;
  }
  .user-container .user-order {
    position: relative;
    padding-bottom: 10px;
    max-height: none;
    display: block;
  }
  .user-container .LandingCurrentOrder {
    width: 94%;
    max-width: 387px;
    margin: 0 auto 12px auto;
    position: relative;
    display: block;
  }
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 991px) {
  .img-home, .img-home img {
    height : 60vh;
  }
  .content-home {
    height: 35vh;
  }
}

/*--- Mobile Landscape and Portrait ---*/
@media (max-width: 767px) {
  .img-home h2 {
    font-size: 3.6em;
  }
  .footer {
    padding-top: 10px;
  }
}

/*--- Mobile Landscape Only ---*/
@media (max-width: 767px) and (min-width: 480px) {
  .img-home, .img-home img {
    height : 45vh;
    min-height: 120px;
  }
  .img-home h2 {
    width: 10em;
    top: -90px;
    margin-bottom: -45px;
  }
  .content-home {
    height: 44vh;
    width: 90vw;
    padding-top: 0px;
  }
  .center {
    margin: 10px auto;
  }
}

/*--- Mobile Portrait Only ---*/
@media (max-width: 479px) {
  .img-home, .img-home img  {
    height : 50vh;
    min-height: 220px;
  }
  .img-home h2 {
    top: -220px;
    margin-bottom: -110px;
  }
  .content-home {
    height: 43vh;
    padding-top: 20px;
  }
}
