.DietModalButton {
  color: #3c6142;
  display: inline-block;
  width: 31%;
  margin: 4px;
  height: 52px;
  padding: 12px;
}

.DietModalButton, .DietModalButton:focus {
  background-color: #FAF7F4;
  border: 1px solid #FAF7F4;
}

.DietModalButton img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}


/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .DietModalButton {
    width: 22%;
    padding: 12px 0;
    font-size: 0.9em;
  }
  .DietModalButton img {
    width: 20%;
    margin-right: 4%;
    margin-top: -5px;
  }
}



/* Mobile Portrait Only*/
@media (max-width: 479px) {
  .DietModalButton {
    width: 45%;
    padding: 13px 0;
    margin: 5px 3px;
  }

  .DietModalButton img {
    width: 20%;
    margin-right: 4%;
  }
}
