.ProgressBar {
  height: 5px;
  background-color: #3c6142;
}
.ProgressBarBackground {
  height: 5px;
  width: 100%;
  background-color: #BEBEBE;
  overflow: hidden;
}
