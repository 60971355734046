.OrderCard {
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 10px;
}

.OrderCard,
.OrderCard > .panel-heading {
  background-color: #F8F3EF;
}

.OrderCard > .panel-heading {
  border-color: #DCE2D6;
  position: relative;
  padding: 10px;
}

.OrderCard .OrderHeader {
  margin-bottom: 10px;
}

.OrderCard .OrderNumber {
  color: #91B698;
  font-weight: bold;
  font-size: 0.9em;
}

.OrderCard .OrderCreated {
  color: #807974;
  font-size: 0.8em;
}

.OrderCard .OrderBody {
  align-items: flex-start;
}

.OrderCard .OrderBody .GglocationIcon {
  margin-top: 3px;
  height: 15px;
  width: auto;
  margin-right: 5px;
  vertical-align: middle;
}

.OrderCard .OrderPickupInformation {
  flex: 1;
  color: #496B4E;
}

.OrderCard .OrderPickupInformation>*,
.OrderCard .OrderOtherInformation>* {
  display: block;
  margin-bottom: 7px;
}

.OrderCard .OrderOtherInformation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.OrderCard .GglocationName {
  font-weight: bold;
}

.OrderCard .GglocationName li img {
  display: none;
}

.OrderCard .OrderStatusDescription {
  font-size: 0.9em;
}

.OrderCard .OrderStatusDescription time {
  font-weight: bold;
}

.OrderCard .OrderStatusLabel {
  padding: 0px 10px;
  font-size: 0.8em;
  color: #FFFFFF;
  margin-right: -10px;
}

.OrderCard .OrderStatusLabel.Pending {
  background-color: #92D27F;
}

.OrderCard .OrderStatusLabel.Cancelled {
  background-color: #8CA1A5;
}

.OrderCard .OrderStatusLabel.Completed {
  background-color: #92C57A;
}

.OrderCard .OrderStatusLabel.Ready {
  background-color: #264F0E;
}

.OrderCard .OrderPrice {
  color: #738B75;
  padding-right: 8px;
}

.OrderCard .OrderPrice .CurrencySymbol {
  font-size: 11px;
}

.OrderCard .OpenIndicator {
  position: absolute;
  right: 5px;
  bottom: 20px;
  color: #91B698;
}

.OrderCard .OrderItemsList>ul {
  display: flex;
  color: #668169;
}

.OrderCard .OrderItemsList>ul>li:nth-child(1) {
  font-size: 0.9em;
}

.OrderCard .OrderItemsList>ul>li:nth-child(2) {
  padding-left: 5px;
}

.OrderCard .OrderItemsList>ul>li:nth-child(2)::before {
  content: 'x ';
}

.OrderCard .OrderItemsList>ul>li:nth-child(3) {
  flex: 1;
  text-align: right;
  font-size: 0.9em;
}

.OrderCard .Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px -15px -15px -15px;
  padding: 15px;
  border-top: solid 1px #ddd;
}

.OrderCard .Footer .Button.blue-link {
  font-size: 0.9em;
}

.OrderCard .Footer .Button.btn-primary {
  margin: -10px;
  padding: 8px 24px;
  float: right;
  border-radius: 3px;
  box-shadow: none;
  font-size: 0.9em;
}
