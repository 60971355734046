.QuantitySelector {
  width: 100%;
  background-color: #F2EDE9;
}
.QuantitySelector .section{
  max-width: 600px;
  position: relative;
}
.QuantitySelector ul li {
  display: inline-block;
  padding: 5px 0;
}
.QuantitySelector ul li.counter {
  position: absolute;
  right: 20px;
  top: 5px;
}
.QuantitySelector ul li.counter ul li{
  padding: 0 6px;
}
.QuantitySelector ul li.counter button {
  font-size: 1.4em;
  position: relative;
  top: 2px;
  opacity: 0.7;
}
.QuantitySelector ul li.counter button:hover {
  opacity: 1;
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .QuantitySelector {
    width: 100%;
    background-color: #F2EDE9;
  }
  .QuantitySelector .section{
    margin: auto;
  }
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .QuantitySelector {
    width: 100%;
    background-color: #F2EDE9;
  }
  .QuantitySelector .section{
    margin: auto;
  }
}
