.MenuDetailsView .IngredientsPicker {
  padding: 2px 20px;
  width: 100%;
  text-align: left;
  justify-content: left;
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .MenuDetailsView {
    padding-bottom: 80px;
  }

  .NutritionalInformationButton {
    display: inline-flex !important;
  }
}
