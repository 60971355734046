.CaloriePercent li {
  color: #3c6142;
  display: inline-block;
}
.CaloriePercentBar {
  width: 50%;
}
.CaloriePercent .EnergyLabel {
  min-width: 50px;
  width: 15%;
  font-size: 1em;
}
.CaloriePercent li:nth-child(2) {
  padding-right: 14px;
  font-size: 0.85em;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .CaloriePercent .EnergyLabel {
    width: 20%;
  }
  .CaloriePercent .EnergyAmount {
    width: 15%;
  }
}
