.IngredientsSection {
  text-align: left;
  padding: 2px 20px;
}
.IngredientsSection h5 {
  font-size: 1.1em;
}
.IngredientsSection h5 span {
  margin-left: 5px;
  font-size: 0.75em;
  position: relative;
  top: -2px;
}

.IngredientsSectionName {
  display: inline-block;
  float: left;
}

.Description {
  margin-top: 0;
  margin-bottom: 10px;
  clear: both;
  font-size: 0.85em;
}

.DietInformation {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
}

.IngredientsList {
  width: 100%;
  display: inline-flex;
  padding-top: 3px;
  flex-wrap: wrap;
}

.SectionToggle {
  float: right;
  margin-top: 3px;
  display: inline-block;
  margin-left: 15px;
  margin-top: 4px;
}

.SectionToggle .switch {
  vertical-align: middle;
  margin-left: 10px;
  margin-bottom: 0;
}

/*--- Tablet & Mobile---*/
@media (max-width: 992px) {
  .IngredientsSection h5 {
    font-size: 1.3em;
  }
}
