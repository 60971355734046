/* .item */
.Gglocation {
  display: block;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
}
.Gglocation .GglocationName {
  /* Force redraw */
  position: relative;
  transform: translate(0, 0);
  z-index: 10;
}
.Gglocation:not(.disabled):hover {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);
}
.Gglocation.disabled {
  opacity: 0.5;
}

.Gglocation .store-img {
  position: relative;
  top: 0;
  width: 100%;
  height: 96px;
}
.Gglocation .store-img img {
  width: 100%;
  height: 96px;
  object-fit: cover;
}
.Gglocation ul.store-info {
  background-color: #f5f2ed;
  color: #3c7162;
  text-align: left;
  padding: 13px 12px;
  position: relative;
  width: 100%;
}
.Gglocation ul.store-info li {
  display: inline-block;
  font-size: 0.9em;
}

.Gglocation ul.store-info li:first-child img {
  width: 17px;
  height: auto;
  margin-right: 3px;
}
.Gglocation ul.store-info li:nth-child(2) img {
  width: 14px;
  height: auto;
  margin-right: 3px;
}
.Gglocation ul.store-info li:nth-child(2) {
  position: absolute;
  right: 12px;
}

.Gglocation.selected {
  border: 2px solid #92D27F;
}

.Gglocation.selected .store-img::before{
  content:"";
}

.Gglocation .GglocationName li {
  color: white;
  background-color: #3c6142;
  padding: 8px 15px;
  display: inline-block;
  z-index: 998;
  position: absolute;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  /* .item */
  .Gglocation {
    margin-bottom: 15px;
  }
}
