.OrderGglocation {
  width: 80%;
  max-width: 420px;
  margin: 12px auto;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
   box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
   border: 1px solid #fff;
}
.OrderGglocation img:not(.DistanceIcon) {
  width: 100%;
  height: 110px;
  object-fit: cover;
}
.OrderGglocation .Distance {
  position: absolute;
  right: 10px;
}
.OrderGglocation ul {
  background-color: #F2EDE9;
  position: relative;
  padding: 6px 15px 10px 15px;
  text-align: left;
}
.OrderGglocation ul li p {
  font-size: 0.9em;
}
.OrderGglocation ul li {
  display: inline-block;
  text-align: left;
}
.OrderGglocation h5 {
  font-size: 1.1em;
}
.OrderGglocation ul li:first-child {
  max-width: 75%;
}
.OrderGglocation ul li:first-child p {
  margin-bottom: 4px;
}
.OrderGglocation ul li img {
  width: 12px;
  height: auto;
  margin-right: 6px;
}
.OrderGglocation ul li:nth-child(2) {
  position: absolute;
  right: 10px;
  top: 14px;
}
.OrderGglocation ul li:nth-child(3) {
  position: absolute;
  right: 10px;
  bottom: 12px;
  font-size: 0.9em;
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .OrderGglocation {
    width: 85%;
    max-width: 360px;
  }
}

/*--- Mobile Landscape and Portrait --*/
@media (max-width: 767px) {
  .OrderGglocation {
    width: 90%;
    max-width: 440px;
  }
}
