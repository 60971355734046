/* .ItemSeparator */
.ItemSeparator ul li {
  display: inline-block;
}
.ItemSeparator .line {
  border-top: 1px solid rgba(60,97,66,0.4);
  margin-top: 20px;
  height: 10px;
  width: 100%;
}
.ItemSeparator {
  position: relative;
  text-align: center;
}
.ItemSeparator p {
  background-color: #e8e5e0;
  position: absolute;
  top: 12px;
  left: 24px;
  padding: 0 5px;
  font-size: 0.9em;
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .ItemSeparator {
    margin-bottom: -22px;
  }
  .ItemSeparator p {
    position: relative;
    top: -24px;
    left: 0;
  }
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .ItemSeparator {
    margin-bottom: -22px;
  }
  .ItemSeparator p {
    position: relative;
    top: -24px;
    left: 0;
  }
}
