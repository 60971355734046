.Menu.navbar-fixed {
  padding-top: 45px;
}

.Menu .SelectedRewardWrapper {
  margin-top: 20px;
}

.Menu .LoyaltyRewardsList {
  margin-top: 10px;
}

.section {
  clear:both;
  text-align: center;
  margin-bottom: 10px;
}

.cyo {
  text-align: center;
  width: 80vw;
  max-width: 962px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
  margin: 20px auto;
}

/*--- Mobile Landscape & Portrait---*/
@media (max-width: 767px) {
  .cyo {
    width: 90vw;
    margin: 10px auto;
  }
}

/* Mobile Landscape */
@media (max-width: 767px) and (min-width: 480px) {
  .cyo {
    width: 91vw;
    margin: 10px auto;
  }
}
