.OrderCancel .modal-dialog {
  width: 420px;
}
.OrderCancel.modal .modal-dialog .modal-body {
  padding: 25px;
}
.OrderCancel .modal-content {
  background-color: #FAFAF5;
}
.OrderCancel .modal-content p {
  padding-bottom: 6px;
}
.OrderCancel .modal-body>ul {
  margin-bottom: 15px;
}
.OrderCancel .modal-input {
  margin-bottom: 20px;
  width: 95%;
  margin-top: 7px;
}
.OrderCancel .modal-body>.Button {
  width: 80%;
}

.OrderCancel .ButtonBar {
  margin-top: 30px;
}

.OrderCancel .ButtonBar .Button {
  display: inline-block;
  width: calc(50% - 10px);
  margin: 0 5px;
}

@media (max-width: 767px) {
  .OrderCancel .modal-dialog {
    width: 100vw;
    margin: 50px 0 0 0;
  }
  .OrderCancel .modal-content {
    max-width: 360px !important;
  }
}
