/* .store-container */
.StoreContainer {
    width: calc(100% - 63vw);
    float: right;
    margin: auto;
}

.StoreContainer .Disclaimer {
  padding: 5px 10px 10px;
  text-align: center;
  color: #3B6142 !important;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  /* .store-container */
  .StoreContainer {
    width: 100%;
    float: none;
  }

  .StoreContainer .Disclaimer {
    padding: 20px 10px 0;
  }
}
