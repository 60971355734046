.Account {
  text-align: center;
  margin: auto;
  display: flex;
}
.Account .DesktopBarWrapper {
  width: 23%;
  background-color: #F2EDE9;
}
.Account .AccountMainWrapper {
  width: 77%;
}

@media (min-width: 1100px) {
  .Account .DesktopBarWrapper {
    width: calc(100vw - 0.77 * 1100px);
  }
  .Account .AccountMainWrapper {
    width: calc(100vw - 0.23 * 1100px);
  }
}

.Account .DesktopBar {
  float: right;
  padding: 45px 40px 0 0;
  width: 100%;
  max-width: calc(1100px * 0.23);
  text-align: right;
  color: #3c6142;
}
.Account .AccountMain {
  width: 100%;
  max-width: calc(1100px * 0.77);
  text-align: left;
  padding: 45px 0 0 40px;
  min-height: calc(100vh - 45px);
}
.Account h5 {
  margin-bottom: 8px;
}

.Account .logout-link {
  color: #3C6142;
  font-style: italic;
  font-size: 0.9em;
}
.Account .logout-link .lnr {
  margin-right: 3px;
}

.Account a.logout-link:hover, .Account .DesktopBar ul li a:hover {
  text-decoration: underline;
}
.Account .DesktopBar ul {
  margin-top: 24px;
}
.Account .DesktopBar ul li{
  line-height: 2.9em;
  position: relative;
  overflow: visible;
}
.Account .DesktopBar ul li:first-child {
  font-size: 0.8em;
  color: #7D7769;
}
.Account .DesktopBar ul li a{
  color: #3c6142;
  padding-bottom: 4px;
}
.Account .DesktopBar ul li.active a{
  border-bottom: 2px solid #3c6142;
}
.Account .DesktopBar ul li.active::after {
  width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left:7px solid #3c6142;
  content: "";
  position: absolute;
  top: 15px;
  right: -15px;
}
.Account .AccountMain ul.title-function {
  margin-bottom: 12px;
}

.Account .AccountMain ul.past-order {
  max-width: 658px;
  position: relative;
}
.Account .AccountMain ul.title-function li {
  display: inline-block;
  color: #3c6142;
}
.Account .AccountMain ul.title-function li:nth-child(2){
  font-size: 1.6em;
  position: absolute;
  right: 35px;
  top: 8%;
}
.Account .AccountMain ul.title-function li:nth-child(3){
  position: absolute;
  font-size: 1.4m;
  right: 0;
  top: 22%;
}
.Account ul.past-order-list {
  max-width: 658px;
  position: relative;
  margin-bottom: 50px;
}
.Account .panel-default {
  border: none;
}
.Account .panel-group {
  margin-bottom: 12px;
}
.Account .panel {
  border-radius: 10px;
}
.Account .panel-heading {
  color: #3C6142;
  background-color: #F8F3EF;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
     box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
}
.Account .panel-title a:hover, .Account .panel-title a:focus {
  color: initial;
}
.Account .panel-title ul {
  position: relative;
}
.Account .panel-title ul li {
  display: inline-block;
}
.Account .panel-title ul li p {
  color: #3c6142;
  font-size: 0.85em;
  letter-spacing: 0;
  margin-bottom: 4px;
}
.Account ul.past-order-list .panel-title ul li p.order-date{
  color: #958F88;
  font-size: 0.75em;
  margin-bottom: 8px;
  margin-top: 4px;
}
.Account ul.past-order-list .panel-title ul li p{
  font-size: 0.85em;
  letter-spacing: 0;
}
.Account ul.past-order-list .panel-title ul li p.order-loc span{
  color: #958F88;
}
.Account ul.past-order-list .panel-title ul li:last-child {
  position: absolute;
  right: 0px;
  top: 35%;
  font-size: 1.2em;
}
.Account ul.past-order-list .panel-title ul li:last-child span:first-child {
  font-size: 0.7em;
  margin-right: 4px;
}
.Account ul.past-order-list .panel-title ul li:last-child span:last-child {
  font-size: 0.75em;
  margin-left: 8px;
  color: #91B698;
}
.Account .panel-body {
  background-color: #F8F3EF;
  border-top: 1px solid #E2DFD8;
  padding: 10px 15px;
}
.Account ul.past-order-list .order-item-list ul li {
  display: inline-block;
  color: #3c6243;
  font-size: 0.95em;
  padding: 3px 0;
}
.Account ul.past-order-list .order-item-list ul li:first-child {
  width: 74%;
}
.Account ul.past-order-list .order-item-list ul li:nth-child(n+2) {
  width: 12%;
  text-align: right;
}
.Account .panel-footer {
  background-color: #F8F3EF;
  border-top: 1px solid #E2DFD8;
  margin: 15px -15px -15px;
}
.Account ul.past-order-list .panel-footer button {
  color: #0092AC;
  font-size: 0.95em;
  padding: 6px 0;
}
.Account ul.past-order-list .panel-footer button:hover {
  text-decoration: underline;
}
/* allegic list */
.Account ul.allegic-list>li {
  display: inline-block;
  background-color: #F8F3EF;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
     box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  border-radius: 5px;
  width: 43%;
  margin: 0 10px 14px 0;
  padding: 0 14px;
}

.Account ul.allegic-list img,
.Account ul.allegic-list-edit img {
  width: 28px;
  height: 28px;
  margin: 14px 0;
}
.Account ul.allegic-list>li>ul,
.Account ul.allegic-list-edit>li>ul {
  width: 100%;
  position: relative;
}

.Account ul.allegic-list>li>ul>li,
.Account ul.allegic-list-edit>li>ul>li {
  display: inline-block;
  color: #3c6142;
  vertical-align: middle;
  position: relative;
}
.Account ul.allegic-list>li>ul>li:nth-child(2),
.Account ul.allegic-list-edit>li>ul>li:nth-child(2) {
  margin-left: 8px;
  position: relative;
  top: 6px;
}
.Account ul.allegic-list>li>ul>li:nth-child(3) {
  position: absolute;
  border-left: 1px solid #E2DFD8;
  top: 0;
  right: 0px;
  padding: 16px 0px 14px 12px;
}

.Account ul.allegic-list>li>ul>li:nth-child(3) button,
.Account ul.cc-list>li>ul>li:nth-child(n+2) button {
  width: 100%;
  height: 100%;
}

.Account ul.allegic-list>li>ul>li:nth-child(3) button .lnr, .Account ul.cc-list>li>ul>li:nth-child(3) button .lnr {
  font-size: 1.3em;
  margin: 0;
}
.Account .btn-hollow {
  width: 43%;
  margin-top: 15px;
}
/*-- edit allegic list --*/
.Account ul.allegic-list-edit>li {
  display: inline-block;
  background-color: #F8F3EF;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
     box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  border-radius: 5px;
  width: 28%;
  margin: 0 10px 14px 0;
  padding: 0 14px;
}
.Account ul.allegic-list-edit>li>ul>li:nth-child(3) {
  position: absolute;
  top: 6px;
  right: 0px;
  padding: 9px 0px 14px 12px;
}
.Account button.allegic-save {
  width: 28%;
}
/* fav menu */
.Account ul.fav-menu-list {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.Account ul.fav-menu-list>li {
  width: 75%;
  background-color: #F8F3EF;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
     box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  margin: 0 14px 14px 0;
  color: #3c6142;
}
.Account ul.fav-menu-list>li>ul {
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: 100%;
}

.Account ul.fav-menu-list>li>ul>li:first-child {
  background-color: #ffffff;
  width: 90px;
  height: auto;
}
.Account ul.fav-menu-list>li>ul>li:first-child img {
  width: 100%;
  height: inherit;
}
.Account ul.fav-menu-list>li>ul>li:nth-child(2) {
  width: calc(100% - 160px);
  padding: 4px 8px;
}
.Account ul.fav-menu-list>li>ul>li:nth-child(2) h5 {
  font-size: 1em;
}
.Account ul.fav-menu-list>li>ul>li:nth-child(2) h5 span {
  font-size: 0.85em;
}
.Account ul.fav-menu-list>li>ul>li:nth-child(2) p {
  font-size: 0.8em;
  line-height: 1.4em;
}
.Account ul.fav-menu-list>li>ul>li:nth-child(2) p.customized {
  font-style: italic;
}
.Account ul.fav-menu-list>li>ul>li:nth-child(3) {
  position: absolute;
  border-left: 1px solid #E2DFD8;
  top: 0;
  right: 0px;
  padding: 16px 8px 14px 10px;
  height: 100%;
}

.Account ul.fav-menu-list>li>ul>li:nth-child(3) button {
  width: 100%;
  height: 100%;
}

.Account ul.fav-menu-list>li>ul>li:nth-child(3) button span {
  font-size: 1.4em;
  margin: 0;
}
/*- save credit card */
.Account ul.cc-list>li {
  display: block;
  background-color: #F8F3EF;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
     box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  border-radius: 5px;
  width: 53%;
  margin-bottom: 14px;
  padding: 0 14px;
  color: #3c6142;
}
.Account ul.cc-list>li>ul {
  position: relative;
}
.Account ul.cc-list>li>ul li {
  display: inline-block;
  padding: 16px 0 14px 0;
}
.Account ul.cc-list>li>ul>li:nth-child(3) {
  position: absolute;
  border-left: 1px solid #E2DFD8;
  top: 0;
  right: 0px;
  padding: 16px 0px 14px 12px;
}
.Account ul.cc-list>li>ul>li:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
}
/* edit account */
.Account .panel-group {
  margin-bottom: 20px;
}

.Account .panel-title p {
  margin-bottom: 0;
}

.Account .panel-group>.panel {
  display: block;
  width: 76%;
  color: #3c6142;
}
.Account .panel-group .panel {
  background-color: #F8F3EF;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  margin-bottom: 14px;
  border-radius: 2px;
  overflow: hidden;

}
.Account .panel-group .panel a, .Account .panel-group .panel .panel-title {
  color: #3c6142;
}
.Account .panel-group .panel ul {
  position: relative;
  width: 100%;
}

.Account .panel-group .panel ul li,
.Account .panel-group .panel ul li {
  display: inline-block;
  padding: 16px 0;
}
.Account .panel-group .panel ul li,
.Account .panel-group .panel ul li {
  display: inline-block;
  padding: 8px 0 6px 0;
}

.Account .panel-group .panel ul li.form-label {
  width: 20%;
}
.Account .panel-group .panel ul li.pen {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 1.1em;
}
.Account .panel-group .panel .dropdown-menu {
  position: absolute;
}
.Account .panel-group .panel .dropdown-menu li {
  padding: 0;
  display: block;
}
.Account .edit-name .panel-body > ul > li:first-child,
.Account .edit-phone .panel-body > ul > li:first-child,
.Account .edit-password .panel-body > ul > li:first-child {
  width: 20% !important;
  vertical-align: middle;
}
.Account .edit-name .panel-body > ul > li:last-child,
.Account .edit-phone .panel-body > ul > li:last-child,
.Account .edit-password .panel-body > ul > li:last-child {
  width: 60% !important;
  vertical-align: middle;
}
.Account .edit-name .panel-body > ul > li:last-child input,
.Account .edit-phone .panel-body > ul > li:last-child input,
.Account .edit-password .panel-body > ul > li:last-child input,
.Account .edit-phone .panel-body > ul > li:last-child div button {
  width: 100% !important;
}
.Account .panel-group .panel-footer button {
  padding: 12px 40px !important;
  width: 140px;
  text-align: center;
  margin-right: 8px;
}
.Account p.notice {
  font-style: italic;
  font-size: 0.9em;
}
.Account p.notice span {
  display: inline-block;
  border: 1px solid #3c6142;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  text-align: center;
  color: #3c6142;
}
/*- edit saved credit card */
.account-form {
  margin: 20px 0;
}
.account-form .default-card {
  text-align: left;
  margin-top: 8px;
  margin-bottom: 12px;
}
.account-form .default-card span {
  position: relative;
  top: -13px;
  left: 10px;
}
/* account feedback sticker */

.Account input.input-alert {
  border: 1px solid #D55157 !important;
  color: #D55157;
}

.edit-account-p {
  width: 76%;
}
.edit-card-p {
  width: 53%;
}
.Account .edit-account {
  width: 100%;
}
.Account .Select.edit-account {
  padding: 0 15px;
}
.Account .edit-account button {
  width: 100%;
}
.Account .ant-calendar-picker {
  width: 100%;
}
.Account .ant-calendar-picker input {
  border: 1px solid #E2DFD8 !important;
  border-radius: 5px;
  padding: 12px !important;
  font-family: 'Sentinel A', 'Sentinel B', serif;
  background-color: rgba(0, 0, 0, 0);
  color: #3c6142;
  box-shadow: none;
  height: 46px;
  font-size: 15.5px;
}
input.edit-account, .account-list .dropdown button {
  border: 1px solid #E2DFD8 !important;
  border-radius: 5px;
  padding: 12px !important;
  font-size: 1em;
  font-family: 'Sentinel A', 'Sentinel B', serif;
  background-color: transparent;
  color: #3c6142;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 100%;
}
input.edit-account:focus {
  outline: none;
  border: 1px solid #BEB6A3;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.Account .Select {
  font-size: initial;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  /*--- account ---*/
  .Account {
    max-width: 550px;
    width: 90%;
  }
  .Account .AccountMainWrapper {
    width: 100%;
  }
  .Account .AccountMain {
    width: 100%;
    padding: 20px 0 60px 0;
    border-left: none;
    min-height: initial;
  }
  .Account ul.past-order-list {
    max-width: none;
    margin-bottom: 40px;
  }
  .Account ul.past-order-list .panel-footer button {
    padding: 10px 0;
    text-align: center;
    width: 100%;
  }
  /* allegic list */
  .AccountMain>p {
    text-align: center;
    width: 90%;
    margin: auto;
  }
  .Account ul.allegic-list>li {
    width: 48%;
    margin: 0 8px 14px 0;
    padding: 0 14px;
  }

  .Account .btn-hollow {
    width: 100%;
  }
  /* fav menu */
  .Account ul.fav-menu-list>li {
    width: 100%;
    margin: 0 0 14px 0 !important;
  }
  /*- save credit card */
  .Account ul.cc-list>li {
    width: 100%;
  }
  /* edit account */
  .Account .panel-group {
    margin-bottom: 0;
  }
  .Account .panel-group .panel {
    width: 100%;
  }

  .Account .panel-group .panel ul li.form-label {
    width: 20%;
    min-width: 86px;
  }
  .Account .panel-group .panel ul li.pen {
    right: 5px;
  }
  .Account .edit-name .panel-body > ul > li:first-child,
  .Account .edit-password .panel-body > ul > li:first-child,
  .Account .edit-phone .panel-body > ul > li:first-child {
    width: 25% !important;
    min-width: 80px;
  }
  .Account .edit-name .panel-body > ul > li:last-child,
  .Account .edit-password .panel-body > ul > li:last-child,
  .Account .edit-phone .panel-body > ul > li:last-child {
    width: 65% !important;
  }
  .Account .panel-group .panel-footer {
    text-align: center;
  }
  .Account .panel-group .panel-footer button {
    padding: 12px 20px !important;
    width: 100px;
    text-align: center;
    margin-right: 8px;
  }
  .Account h5.section-title-m {
    font-size: 1.3em;
    margin-bottom: 14px;
    padding-left: 3px;
  }
  .edit-account-p, .edit-card-p {
    width: 100%;
  }
  /* edit saved card */
  /*- edit saved credit card */
  .account-form {
    margin: 10px auto;
  }
  .account-form .default-card {
    text-align: center;
    margin-left: -20px;
  }
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .Account .AccountMain {
    padding: 30px 0 0 0;
  }
  /*-- allegic edit */
  .Account ul.allegic-list-edit {
  }

  .Account ul.allegic-list-edit>li {
    width: calc((100% - 14px) / 2);
  }
  .Account ul.allegic-list-edit>li:nth-child(even) {
    margin-right: 0;
  }
  .Account button.allegic-save {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .Account .form-button {
    width: 100%;
    text-align: center;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .Account .AccountMain .form-button button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 !important;
  }
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .Account ul.allegic-list-edit {
  }

  .Account ul.allegic-list-edit>li {
    width: calc((100% - 14px) / 2);
  }
  .Account ul.allegic-list-edit>li:nth-child(even) {
    margin-right: 0;
  }
  .Account button.allegic-save {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .Account .form-button {
    width: 100%;
    text-align: center;
  }
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .Account ul.allegic-list>li {
    width: 100%;
    margin: 0 auto 14px auto;
    padding: 0 14px;
  }
  /*-- allegic edit */
  .Account ul.allegic-list-edit {
  }

  .Account ul.allegic-list-edit>li {
    width: 100%;
  }
  .Account ul.allegic-list-edit>li:nth-child(even) {
    margin-right: 0;
  }
  .Account button.allegic-save {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .Account .form-button {
    width: 100%;
    text-align: center;
  }
}
