.TopBar {
  width: 100%;
  height: 45px;
  background-color: #35553a;
  vertical-align: middle;
  text-align: center;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.4);
}

.TopBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 70000;
  display: flex;
  padding: 0 10px;
  color: #fff;
}

.TopBar .TopBarTitle {
  flex: 1;
}

.TopBar .TopBarLeftButton,
.TopBar .TopBarRightButton {
  display: flex;
  align-items: center;
}

.TopBar .lnr {
  font-size: 1.5em;
}

.TopBar .Button > span {
  display: flex;
  align-items: center;
}

.TopBar .TopBarLeftButton .BackButtonDesktop span {
  padding-right: 4px;
}

.TopBar .TopBarLeftButton .BackButtonDesktop span img {
  width: 10px;
  height: auto;
}

body {
  padding-top: 45px !important;
}

ul.topbar-info li {
  padding: 0 5px;
  display: inline-block;
  color: #fff;
}
ul.topbar-info li:first-child p{
  font-style: italic;
}
ul.topbar-info li img {
  width: 26px;
  height: auto;
  height: auto;
  margin: -6px 2px 0 2px;
}
.TopBar .white-logo-big img {
  height: 34px;
  width: auto;
  margin-top: 6px;
}
.TopBar .btn-right {
  color: #fff;
  position: absolute;
  padding-top: 0px;
  top: 0px;
  right: 10px;
}
.TopBar .btn-right .CartButton .Button {
  padding-top: 10px;
  padding-bottom: 5px;
}
.TopBar .btn-right .btn-list {
  float: right;
  padding-left: 5px;
}
.TopBar .btn-right button.btn-text {
  font-size: 0.9em;
}

.TopBar .with-icon {
  padding-top: 12px;
}
.TopBar p {
  color: #ffffff;
  padding-top: 12px;
  font-size: 18px;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .TopBar .btn-right .lnr {
    font-size: 1.7em;
  }
}
