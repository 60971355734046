/* .all-store */
.GglocationsList {
  padding: 0 12px 0 25px;
  position: relative;
  height: calc(100vh - 136px);
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  margin-top: 10px;
}

.GglocationsList .store-list {
  overflow-y: hidden;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  /* .all-store */
  .GglocationsList {
    padding: 0;
    position: relative;
    height: 100% !important;
    overflow-y: auto !important;
  }

  .GglocationsList .store-list {
    overflow-y: hidden;
  }
}


/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  /* .all-store */
  .GglocationsList .store-list {
    padding: 10px 25px 0 25px;
  }
}

/*--- Mobile Landscape and Portrait only ---*/
@media (max-width: 767px) {
  /* .all-store */
  .GglocationsList .store-list {
    padding: 10px 16px 0 16px;
  }
}
