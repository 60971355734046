.OrderStatus {
  margin-top: 30px;
  text-align: center;
}
.OrderStatus ul {
  margin: 0 auto;
  position: relative;
  width: 60%;
  padding-bottom: 50px;
}

.OrderStatus ul li {
  display: inline-block;
  color: #3c7142;
  font-size: 1.13em;
  vertical-align: middle;
}
.OrderStatus ul li img.iconic{
  width: 16px;
  margin-right: 6px;
  margin-top: -2px;
}
.OrderStatus ul li:first-child {
  position: absolute;
  left: 0;
  text-align: left;
  top: 18px;
}
.OrderStatus.OrderStatusAccepted ul li:first-child {
  top: initial;
}
.OrderStatus ul li.timer {
  text-align: right;
  position: absolute;
  right: 0;
  top: 3px;
  font-size: 1.4em;
  height: 50px;
}
.OrderStatus ul li.timer img {
  height: 100%;
}
.OrderStatus span.bold {
  font-size: 1.1em;
}
.OrderStatus span.time-actual {
  font-size: 0.85em;
  line-height: 1.3em;
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .OrderStatus ul {
    width: 85%;
    max-width: 360px;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .OrderStatus {
    margin-top: 22px;
  }
  .OrderStatus ul {
    width: 80%;
    max-width: 440px;
    padding-bottom: 36px;
  }
  .OrderStatus ul li {
    font-size: 1em;
  }
  .OrderStatus ul li img.iconic{
    margin-right: 4px;
    position: relative;
    top: -1px;
  }
  .OrderStatus ul li.timer {
    font-size: 1.4em;
  }
}
