.FavoriteMenuItemsList {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.FavoriteMenuItemsList > li {
  flex: 0 0 75%;
}

.FavoriteMenuItemsList .FavoriteMenuItem {
  height: calc(100% - 14px);
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .FavoriteMenuItemsList > li {
    flex: 0 0 100%;
  }
  .FavoriteMenuItemsList .FavoriteMenuItem {
    width: 100%;
  }
}
