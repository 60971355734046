.PromoCodeInputWrapper {
  margin-top: 10px;
}

.PromoCodeInputMain {
  display: flex;
  align-items: center;
}

.PromoCodeInputMain>.Button.btn-primary {
  font-size: 0.9em;
  padding: 0px 30px;
}

.PromoCodeInputMain .PromoCodeInput {
  position: relative;
  flex: 1;
  margin-right: 15px;
}

.PromoCodeInputMain .PromoCodeInput .Input {
  position: relative;
  width: 100%;
  background-color: #e2dfd8 !important;
  font-size: 14px;
}

/* Layering buttons and input */
.PromoCodeInputMain .PromoCodeInput .Input {
  z-index: 1;
}

.PromoCodeInputMain .PromoCodeInput .ClearButton {
  z-index: 2;
}

.PromoCodeInputMain .PromoCodeInput .PlaceholderIcon {
  z-index: 4;
}

.PromoCodeInputMain .PromoCodeInput .Input::placeholder {
  padding-left: 18px;
}

.PromoCodeInputMain .PromoCodeInput .PlaceholderIcon {
  position: absolute;
  left: 10px;
  top: 15px;
  color: #90B797;
}

.PromoCodeInputMain.hasError .PromoCodeInput .PlaceholderIcon {
  color: #D85A5B;
}

.PromoCodeInputMain .PromoCodeInput .ClearButton {
  position: absolute;
  right: 10px;
  top: 15px;
  color: #90B797;
}

.PromoCodeInputMain.hasError .PromoCodeInput .ClearButton {
  color: #D85A5B;
}

.PromoCodeInputMain>.Button {
  padding: 0 12px;
  height: 40px;
}

.PromoCodeInputWrapper .PromoCodeError {
  margin-top: 10px;
  color: #D85A5B;
  text-align: left;
  padding-left: 10px;
}

.PromoCodeInputWrapper .PromoCodeError .lnr {
  margin-right: 5px;
}
