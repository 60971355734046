a.Button, a.Button:hover, a.Button:focus {
  color: inherit;
}

.Button {
  display: inline-flex;
}

.Button > span {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.Button.disabled {
  opacity: 0.8;
}

.Button.loading {
  position: relative;
}

.Button.loading .fa-spin {
  position: absolute;
  top: 20px;
  left: 20px;
}

.Button.btn-teal {
  color: #FFF;
  background-color: #91B698;
  border: solid 1px #90bf99;
}
.Button.btn-teal:hover, .Button.btn-teal:focus, .Button.btn-teal:active {
  color: #FFF;
  background-color: #91B698 !important;
  border: solid 1px #90bf99;
}
.Button.btn-primary {
  color: #FFF;
  background-color: #3c6142;
  padding: 12px 60px;
  font-size: 1em;
  border: 1px solid #507d51;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
  outline: 0!important;
}
.Button.btn-primary:hover, .Button.btn-primary:focus, .Button.btn-primary:active {
  color: #FFF;
  background-color: #315738 !important;
  outline: 0 !important;
  border: 1px solid #507d51;
}
.Button.disabled.btn-primary:hover {
  background-color: #3c6142;
}
.Button.btn-secondary {
  border: 1px solid #3c6142;
  padding: 16px 60px;
  display: block;
  font-size: 1em;
  color: #3c6142;
}
.Button.btn-secondary:hover, .Button.btn-secondary:focus {
  background-color: #3c6142;
  color: #ffffff;
}
.Button.btn-gray {
  border: 1px solid #8CA1A5;
  background-color: #8CA1A5;
  color: #fff;
  padding: 12px 60px;
  font-size: 1em;
}
.Button.btn-gray:hover, .Button.btn-gray:active, .Button.btn-gray:focus {
  border: 1px solid #69868B;
  color: #FFF;
  background-color: #69868B !important;
}
.Button.btn-round {
  background-color: #8CA1A5;
  border-radius: 15px;
  color: #fff;
  padding: 8px 12px;
  font-size: 0.9em;
}
.Button.btn-round:hover, .Button.btn-round:active, .Button.btn-round:focus {
  color: #FFF;
  background-color: #69868B !important;
}
.Button a {
  color: inherit;
}
.Button a:focus {
  color: inherit;
}
.Button a:hover {
  color: inherit;
}

.Button.btn-grey {
  background-color: #8CA1A5;
  padding: 12px 60px;
  font-size: 1em;
  border: 1px solid #8CA1A5;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
  outline: 0!important;
  color: #ffffff;
}
.Button.btn-grey:hover, .Button.btn-grey:focus, .Button.btn-grey:active {
  background-color: #81989D !important;
  outline: 0 !important;
  border: 1px solid #81989D;
}
.Button.btn-grey a {
  color: #ffffff !important;
}

.Button.Split > span {
  width: 100%;
  display: flex;
  max-width: 300px;
  margin: 0 auto;
}

.Button .Text {
  flex: 1;
  padding-left: 35.5px;
}

.Button .Next {
  margin-right: 20px;
  height: 19px;
  margin-top: 2px;
}
