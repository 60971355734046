.CyoDetails {
    background-color: #F2EDE9;
    width: 100%;
    height: calc(100vh - 331px);
    min-height: 250px;
    border-bottom: 1px solid #fff;
    padding-top: 14px;
}
.CyoDetails .header {
    padding: 0 30px;
}

.CyoDetails .header>h5 {
    font-size: 1.2em !important;
}
.CyoDetails .header>p {
    color: #454545 !important;
    margin-top: 0 !important;
    font-size: 1em !important;
}
.CyoDetails .header>p span {
    padding: 0 2px;
}

.CyoDetails > .content {
  height: calc(100% - 39px);
  width: calc(100% - 23px);
  margin-left: 23px;
  padding-bottom: 5px;
  position: relative;
}

.CyoDetails .IngredientsSectionHeader {
  padding: 0;
  display: inline-block;
}

.CyoDetails .nav-tabs > li {
  flex: initial;
  padding: 0 10px;
}
.CyoDetails .nav-tabs > li:first-child {
  padding-left: 0;
}
.CyoDetails .nav-tabs > li:last-child {
  padding-right: 0;
}

.CyoDetails li.item-added {
     -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
    width: 96px;
    height: auto;
    margin: 0 5px 10px 5px;
    position: relative;
}


.CyoDetails li.item-added .item-img {
    width: 100%;
    height: 80px;
    overflow: hidden;
}


.CyoDetails li.item-added .item-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.CyoDetails li.item-added .item-name {
    padding: 6px 8px;
    width: 96px;
    position: relative;
    text-align: center;
    margin: auto;
    line-height: 1.2em;
    font-size: 0.9em;
}
.CyoDetails li.item-added .item-name p {
    margin-bottom: 0;
}
.CyoDetails .img-remove::after {
   padding-top: 80px;

}
.CyoDetails .item-remove::after {
    top: 10%;
    transform: translateY(0%);
    left: 30%;
    width: 49%;
}

.CyoModal .nav-tabs > li > a {
  padding: 10px 5px;
}

.CyoModal .modal-body > .content-top {
  width: 100%;
  text-align: left;
}

.CyoModal .modal-body > .content-top > h5 {
  font-size: 1em;
}

.CyoModal .NutritionalSection .content-top {
  margin: 0;
}

.CyoDetails .nav-tabs {
  width: 100%;
}

.CyoSectionGroup {
  top: 35px;
  right: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  max-height: calc(100% - 35px);
}

.CyoSectionGroup .IngredientsSection {
  padding: 0;
  display: inline-block;
}

.CyoSectionGroup .IngredientsSectionHeader {
  padding: 0;
  text-align: center;
}

.CyoSectionGroup .IngredientsSectionName {
  float: initial;
}

#NutritionalTabs .tab-content {
  text-align: left;
}

#NutritionalTabs .IngredientsSectionHeader {
  padding: 0;
}

#NutritionalTabs .IngredientsSectionName, .CyoDetails .IngredientsSectionName  {
  float: initial;
  width: 100%;
}

#NutritionalTabs .IngredientsSectionName h5, .CyoDetails .IngredientsSectionName h5 {
  font-size: 1em;
  margin: 0;
  font-weight: 400;
  font-family: 'Sentinel A', 'Sentinel B', serif;
  width: 100%;
  text-align: center;
}

#NutritionalTabs .IngredientsSectionName {
  padding-top: 10px;
}

.NutritionalBlock .IngredientsSectionName h5,
#NutritionalTabs .IngredientsSectionName h5 {
  text-align: left;
}

.CyoSectionGroup {
  padding: 10px 0;
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
}

.CyoModal .CyoSectionGroup {
  position: initial;
}

.CyoModal .IngredientsList, .CyoDetails .IngredientsList {
  height: 100%;
}
