.NutritionalModal {
  display: none;
}

.NutritionalModal.modal .modal-body {
  padding: 10px 0 10px;
}

.NutritionalModal.modal .modal-body .content-top {
  width: 100%;
}

.NutritionalModal .content-top {
  text-align: left;
  padding: 0 20px;
}

.NutritionalModal .nav-tabs > li > a {
  width: 100%;
  border-bottom-color: #DDDDDD;
}

.NutritionalModal .CaloriePercent .EnergyLabel {
  font-size: 0.85em;
}

.NutritionalModal #NutritionalTabs .tab-content {
  padding: 0 10px;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .NutritionalModal {
    display: table;
  }
}

/* Mobile Portrait Only*/
@media (max-width: 479px) {
  .modal .modal-body .content-top {
    margin-top: -60px;
  }
}
