.MenuNavbarWrapper.fixed {
  position: fixed;
  top: 45px;
  left: 0;
  right: 0;
  z-index: 70000;
  border-top: 1px solid #c4bfb5;
}
.MenuNavbarCategories {
  width: 100%;
  overflow: hidden;
}
.MenuNavbar {
  width: 100%;
  height: 56px;
  background-color: #3b6142;
  vertical-align: middle;
  position: relative;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.4);
  white-space: nowrap;
  overflow: hidden;
  z-index: 99999;
}
.MenuNavbar ul {
  min-width: 100%;
  display: table;
}
.MenuNavbar ul li {
  display: inline-block;
  padding: 0 5px 11px 10px;
}
.MenuNavbar ul li a, .MenuNavbar ul li button {
  color: #fff;
  height: 45px;
  display: inline-block;
  padding: 25px 5px 11px 6px;
  vertical-align: bottom;
}
/*.MenuNavbarItem a:focus {
  color: #92D27F;
  border-bottom: 3px solid #92D27F;
}*/
.MenuNavbar-lg ul li:first-child {
  padding: 0 10px 12px 22px;
}
.MenuNavbar-lg ul li:last-child span {
  margin-left: 6px;
}
.MenuNavbar ul li.selected {
  border-bottom: 3px solid #92D27F;
}
.MenuNavbar ul li.selected a {
  color: #92D27F;
}
.category-name {
  padding: 6px 0 2px 0;
}

.MenuSubnav {
  width: 100%;
  height: 45px;
  padding: 20px 0;
  background-color: #F2EDE9;
  vertical-align: middle;
  z-index: 99999;
  border-bottom: 1px solid #E0D7D0;
 -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  z-index: 99999;
}
.MenuSubnav.hidden {
  display: none;
}
.MenuSubnav ul {
  min-width: 100%;
  display: table;
}
.MenuSubnav ul li {
  display: inline-block;
  padding: 0 5px 11px 6px;
  margin-right: 5px;
}
.MenuSubnav ul li a {
  font-size: 0.95em;
  color: #3c6142;
  padding: 10px 5px;
}
.MenuSubnav ul li a:hover {
  position: relative;
  top: -3px;
}

.MenuSubnav ul li:first-child {
  padding-left: 18px;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .MenuNavbarWrapper {
    position: fixed;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 70000;
    border-top: 1px solid #c4bfb5;
  }
  .Menu {
    padding-top: 56px;
  }
  .MenuSubnav {
    margin-top: -8px;
    text-align: center;
  }
}

/* Mobile Landscape and Portrait */
@media (max-width: 767px) {
  .MenuNavbar {
    overflow: initial;
  }
}
