.InputAlertText {
  color: #D55157;
  margin-top: -10px;
  margin-bottom: 20px;
}

.InputAlertText.lower {
  color: #D55157;
  margin-top: 0;
  margin-bottom: 10px;
}
