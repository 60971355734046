.cart .modal-dialog {
  width: 810px;
}
.cart .modal-content {
  min-height: 320px;
  top: 2vh;
}
.cart .modal-content, .cart .modal-body {
  background-color: #E2DFD8;
}
.cart .modal-control {
  padding-top: 26px !important;
  z-index: 9999;
}
.cart .modal-content .CartHeader {
  width: 100%;
  background-color: #F2EDE9;
  color: #3B6142;
  font-weight: bold;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  top: -60px;
  padding: 14px 0;
  margin-bottom: -60px;
}
.cart p.header-small-text {
  font-size: 0.9em !important;
}
.cart .modal-content .CartHeader p {
  font-size: 1.1em;
  margin-bottom: 6px;
}
.cart .modal-content .CartHeader ul li {
  display: inline-block;
}

.cart .cart-body {
  display: flex;
}

.cart .modal-content p.empty-cart-msg {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1em;
}
.cart .empty-cart-msg p {
  width: 30%;
  margin: 12px auto 28px auto;
  line-height: 24px;
}
.cart .CartHeader .cart-count span {
  background-color: #92D27F;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #ffffff;
  margin-left: 6px;
}
.cart .modal-body {
  padding: 12px 12px 22px 12px;
}
.cart .modal-content {
  overflow: visible;
}

.cart .cart-body .cart-item-list {
  width: 50%;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.cart .cart-body .cart-item-list::-webkit-scrollbar {
  width: 8px;
}

.cart .cart-body .cart-item-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.cart .cart-body .cart-item-list::-webkit-scrollbar-thumb {
  background: #b4b4b4;
  border: 1px solid #fff;
}
.cart .cart-body .cart-item-list::-webkit-scrollbar-thumb:window-inactive {
  background: #CECECE;
}

.cart .cart-body .cart-item-list > ul {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: #F2EDE9;
  text-align: left;
  position: relative;
  margin-bottom: 8px;
   -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
     box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
}
.cart .cart-body .cart-item-list p.customer::before {
  content: "Label:";
  font-size: 0.85em;
  color: #7D7769;
  padding-right: 4px;
}
.cart .cart-body .cart-item-list p.qty::before {
  content: "Qty: ";
  font-size: 0.85em;
  color: #7D7769;
}
.cart .cart-body .cart-item-list p.price {
  font-size: 0.9em !important;
  position: absolute;
  right: 0;
  bottom: 5px;
}
.cart .cart-body .cart-item-list {
  display: inline-block;
  vertical-align: text-top;
}
.cart .cart-body .CartCheckout {
  width: 41%;
  position: relative;
  margin: 0 25px 0 35px;
}
.cart .cart-body .CartCheckout p.CartLabel{
  color: #7D7769;
  font-size: 0.85em;
  margin-bottom: 6px;
  text-align: left;
}

.cart .cart-body .CartCheckout .Select, .cart .cart-body .CartCheckout > div {
  width: 100%;
  margin-left: 0;
}
.cart .cart-body .CartCheckout ul.price {
  margin-top: 26px;
  width: 100%;
  position: relative;
  padding-bottom: 3em;
}
.cart .cart-body .CartCheckout ul.price li {
  display: inline-block;
  vertical-align: text-top;
  font-size: 1.1em;
  padding: 0;
  margin: 0;
}
.cart .cart-body .CartCheckout ul.price li:first-child {
  text-align: left;
  position: absolute;
  left: 16px;
}
.cart .cart-body .CartCheckout ul.price li:first-child p {
  margin-bottom: 6px;
}
.cart .TotalPrice {
  font-weight: bold;
}

.cart .cart-body .CartCheckout ul.price li:last-child {
  text-align: right;
  position: absolute;
  right: 16px;
}
.cart .cart-body .CartCheckout ul.price p.count-gst {
  font-size: 0.85em;
  color: #91B698;
}

.cart .cart-body .CartCheckout .cart-button .Button {
  padding: 12px 0;
  width: 100%;
}

.cart .cart-body .CartCheckout .cart-button .Button.loading .fa-spin {
  top: 15px;
}
.cart .cart-body .CartCheckout .cart-button {
  margin-top: 20px;
  width: 100%;
  display: flex;
}

.cart .cart-body .CartCheckout .cart-button .MenuButton {
  width: 50%;
  height: 52px;
  position: relative;
  font-size: 0.9em;
  margin-right: 5px;
}
.cart .cart-body .CartCheckout .cart-button .MenuButton .Button > span {
  display: flex;
}
.cart .cart-body .CartCheckout .cart-button .MenuButton .Button .Caption {
  flex: 1;
}
.cart .cart-body .CartCheckout .cart-button .MenuButton .Button .lnr {
  margin-left: 5px;
  margin-top: 4px;
}
.cart .cart-body .CartCheckout .cart-button .MainButton {
  width: 50%;
  height: 52px;
  position: relative;
  font-size: 0.9em;
  flex-grow: 1;
}
.cart .cart-body .CartCheckout .cart-button .MainButton .Button > span {
  display: flex;
}
.cart .cart-body .CartCheckout .cart-button .MainButton .Button .Caption {
  flex: 1;
}
.cart .cart-body .CartCheckout .cart-button .MainButton .Button .lnr {
  margin-right: 5px;
  margin-top: 4px;
}
.cart .cart-body .CartCheckout .cart-button p {
  color: #3c6142;
  font-size: 0.8em;
  margin-top: 15px;
}

.cart .cart-body .CartCheckout .CartError {
  display: flex;
  padding: 0 10px;
  margin-top: 20px;
  margin-bottom: 0;
}
.cart .cart-body .CartCheckout .CartError .InputAlertText {
  flex: 1;
  text-align: left;
  padding: 0;
  margin: 0;
  width: auto;
}
.cart .cart-body .CartCheckout .CartError .InputAlertText p {
  margin: 0;
}
.cart .cart-body .CartCheckout .CartError .CartErrorButton {
  text-align: right;
}

/* Tablet Only */
@media (min-width: 768px) and (max-width: 992px) {
  .cart .modal-dialog {
    width: 88%
  }
  .cart .empty-cart-msg p {
    width: 50%;
  }
  .cart .modal-content {
    top: 5vh;
  }
  .cart .modal-body {
    padding: 12px 12px 22px 12px;
  }

  .cart .cart-body .cart-item-list {
    width: 50%;
    max-height: 70vh;
    overflow-y: auto;
  }

  .cart .cart-body .CartCheckout {
    width: calc(50% - 25px);
    position: relative;
    margin: 0 10px 0 15px;
  }

  .cart .cart-body .CartCheckout .pick-up-time span.instant-order, .cart .cart-body .CartCheckout .pick-up-time span.schedule-order {
    margin-right: 5px;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .cart.modal.fade.in {
    display: block;
  }
  .cart, .cart .modal-dialog, .cart .modal-content {
    width: 100vw !important;
    min-height: 100vh !important;
    margin: 0;
    padding: 0 !important;
    border: 0;
    border-radius: 0;
    max-width: none;
  }
  .cart .modal-content {
    top: 0 !important;
  }
  .cart .modal-control {
    width: 54px;
    height: 52px;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    left: calc(100% - 60px);
  }
  .cart .modal-control .Button.btn-close-modal{
    width: 54px;
    height: 52px;
    background-image: none;
    position: relative;
    top: 0;
    left: 0;
     }
  .cart .modal-control .Button.btn-close-modal::after {
    content: url(btn-close-modal-white.svg) !important;
    display: block;
    width: 20px;
    height: 18px;
    position: absolute;
    top: 14px;
    right: 8px;
  }

  .cart .modal-content .CartHeader {
    width: 100%;
    height: 45px;
    background-color: #3B6142;
    color: #FFFFFF;
    font-weight: normal;
    vertical-align: middle;
    text-align: center;
    position: relative;
    border-radius: 0;
    padding: 14px 0;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
    z-index: 1000;
    top: -52px !important;
  }
  .cart .modal-content .CartHeader span {
    font-size: 1.7em;
    position: relative;
    top: 4px;
    margin-right: 3px;
  }
  .cart .CartHeader .cart-count span {
    font-size: 1em;
    top: 0;
  }

  .cart .modal-content .CartHeader p {
    font-size: 1.05em;
    margin-bottom: 6px;
    color: #ffffff;
    font-weight: 400 !important;
  }

  .cart .modal-content p.empty-cart-msg {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.2em;
  }
  .cart .empty-cart-msg p {
    font-size: 1.1em;
    width: 60%;
    margin: 12px auto 28px auto;
    line-height: 26px;
  }
  .cart .MainButton {
    width: 70%;
  }
    /*--- cart with item --*/
  .cart .cart-body {
    flex-direction: column;
    text-align: center;
    padding: 14px 10px;
  }
  .cart .cart-body .cart-item-list {
    width: 100%;
    max-height: none;
  }
  .cart-item-list {
    display: block;
  }
  .cart .cart-body .CartCheckout {
    width: 100%;
    padding: 20px 0;
    margin: 0 auto;
  }
  .cart .cart-body .CartCheckout p.cart-label{
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  .cart .cart-body .CartCheckout .pick-up-time {
    font-size: 0.9em;
  }
  .cart .cart-body .CartCheckout .pick-up-time span.instant-order, .cart .cart-body .CartCheckout .pick-up-time span.schedule-order {
    margin-right: 5px;
  }
  .cart .cart-body .CartCheckout ul.price li:first-child {
    left: 10px;
  }
  .cart .cart-body .CartCheckout ul.price li:last-child {
    right: 10px;
  }
  .cart .cart-body .cart-item-list > .CartItem > li:nth-child(2) {
    width: calc(100% - 70px);
  }
}
