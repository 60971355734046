.OrderProgressBar {
  position: relative;
}
.OrderProgressBar ul.bar {
  height: 8px;
  width: 50%;
  margin: 30px auto;
  position: relative;
}
.OrderProgressBar ul.bar li.bar-bg {
  width: 100%;
  height: 100%;
  background-color: #CBCBCB;
  position: absolute;
}
.OrderProgressBar ul.bar li.bar-progress-1 {
  width: 26%;
  height: 100%;
  background-color: #3c6142;
  position: absolute;
  top: 0;
  left: 0;
}
.OrderProgressBar ul.bar li.bar-progress-2 {
  width: 75%;
  height: 100%;
  background-color: #3c6142;
  position: absolute;
  top: 0;
  left: 0;
}
.OrderProgressBar ul.bar li.bar-progress-3 {
  width: 100%;
  height: 100%;
  background-color: #3c6142;
  position: absolute;
  top: 0;
  left: 0;
}
.OrderProgressBar ul.Steps {
  position: relative;
  top: -48px;
  width: 60%;
  margin: auto;
  margin-bottom: -46px;
}
.OrderProgressBar ul.Steps li:first-child {
  position: absolute;
  left: 0;
}

.OrderProgressBar ul.Steps li{
  display: inline-block;
  text-align: center;
  width: 20%;
  font-size: 0.95em;
}
.OrderProgressBar ul.Steps li:first-child {
  position: absolute;
  left: 0px;
}
.OrderProgressBar ul.Steps li:last-child {
  position: absolute;
  right: 0px;
}
.OrderProgressBar ul.Steps li.marked::after {
  width: 28px;
  height: 28px;
  padding: 4px 8px;
  border-radius: 14px;
  content: url(./icon-ticked.svg);
  background-color: #3c6142;
  display: block;
  position: absolute;
  top: 0;
  left: calc((100% - 28px)/2);

}
.OrderProgressBar ul.Steps li div:first-child {
  width: 28px;
  height: 28px;
  background-color: #CBCBCB;
  color: #ffffff;
  border-radius: 14px;
  padding: 5px;
  text-align: center;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
     box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
  margin-bottom: 12px;
  position: relative;
  left: calc((100% - 28px)/2);
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .OrderProgressBar ul.bar {
    width: 70%;
    max-width: none
  }
  .OrderProgressBar ul.Steps {
    width: 80%;
    max-width: 400px;
  }
  .OrderProgressBar ul.Steps li{
    width: 20%;
    font-size: 0.85em;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .OrderProgressBar ul.bar {
    width: 70%;
    margin-top: 30px;
    max-width: 330px;
  }
  .OrderProgressBar ul.Steps {
    width: 90%;
    max-width: 400px;
  }
  .OrderProgressBar ul.Steps li{
    width: 20%;
    font-size: 0.9em;
  }
}
