div[role=dialog] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.modal {
  z-index: 9999999999999 !important;
  width: 100vw;
  height: 100vh;
  justify-content: center;
}
.modal-backdrop.fade.in {
  background-color: #224027;
  opacity: .6;
  z-index: 100000 !important;
}
.modal.fade.in {
  display: flex;
}
.modal .modal-dialog {
  margin: auto;
}
.modal .modal-content {
  background-color: #F2EDE9;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3) !important;
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3) !important;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3) !important;
  border: none;
  border-radius: 3px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal .modal-control {
  position: relative;
  z-index: 999;
  width: 10%;
  height: 60px;
  padding-top: 20px;
  position: relative;
  left: calc(100% - 10%);
}
.modal .modal-backdrop {
  display: none !important;
}
.modal .modal-control button.btn-close-modal{
  width: 18px;
  height: 16px;
  color: #3c6142;
  background-image: url(./btn-close-modal.svg);
}
.modal .modal-body {
  padding: 25px 35px 35px 35px;
}
.modal .modal-body .content-top {
  width: 70%;
  margin: -55px auto 0 auto;
}
.modal .modal-body .content-top h5 {
  margin-bottom: 6px;
}

.modal.modal-small .modal-content {
  width: 282px;
  border-radius: 10px;
  margin: auto;
}
.modal.modal-small .modal-content h3 {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 10px;
}
.modal.modal-small .modal-content p {
  margin-top: 20px;
  line-height: 1.3em;
}
.modal.modal-small .modal-content p span {
  font-weight: 600;
}
.modal.modal-small .modal-content p {
  margin-bottom: 18px;
}
.modal.modal-small .modal-content p.icon {
  color: #E5AA44;
  font-size: 3.4em;
  margin-bottom: 6px !important;
}
.modal.modal-small .modal-content p.icon span {
  font-weight: 400 !important;
}
.modal.modal-small .modal-content .btn-primary {
  margin-top: 15px;
}

.modal-dialog-transparent .modal-content {
  background-color: transparent;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/*--- Mobile Landscape and Portrait ---*/
@media (max-width: 767px) {
  .modal {
    display:table;
    height: 100%;
    width: 100%;
    margin: auto;
  }
  .modal .modal-dialog {
    width: 90%;
    vertical-align: middle;
    pointer-events: none;
  }
  .modal .modal-content {
    /* To center horizontally */
    margin: 20px auto;
    pointer-events: all;
    border-radius: 5px;
    top: 0;
  }
  .modal.modal-small .modal-body {
    padding: 25px 35px 35px 35px;
  }
  .modal.modal-small .modal-content {
    width: 260px;
    max-width: 80vh;
  }
}
/* Mobile Portrait Only*/
@media (max-width: 479px) {
  .modal .modal-control {
    width: 14%;
    height: 60px;
    padding: 12px 10px;
    position: relative;
    left: calc(100% - 14%);
  }
  .modal .modal-control button.btn-close-modal{
    width: 19px;
    height: 17px;
  }
  .modal .modal-dialog {
    max-width: 320px;
  }
  .modal .modal-body {
    padding: 35px 10px 15px 10px;
  }
  .modal .modal-body .content-top {
    width: 80%;
    margin-top: -75px;
  }
  .modal-sm-70vh {
    width: 70vh !important;
  }
}
/*max-height: 85vh;*/

