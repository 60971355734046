.LoyaltyRewardWrapper {
  max-width: 600px;
  margin: 10px auto;
}

.LoyaltyReward {
  display: flex;
  background-color: #F8F3EF;
  margin: 10px 0;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
}

.LoyaltyReward .LoyaltyRewardBody {
  flex: 1;
  padding: 10px;
  font-size: 0.85em;
}

.LoyaltyReward .LoyaltyRewardBody p:last-child {
  margin: 0;
}

.LoyaltyReward .LoyaltyRewardButton {
  padding: 12px 12px;
}

.LoyaltyReward .Name {
  font-size: 1.2em;
}

.LoyaltyReward.Small .Name {
  margin-bottom: 0;
  vertical-align: middle;
  display: inline-block;
}

.LoyaltyReward.Small .Validity {
  display: none;
}
