@import "../../css/bootstrap.css";
@import "../../css/animations.css";

body {
  margin: 0;
  padding: 0;
  background-color: #e8e5e0;
  overflow-x: hidden;
  padding-bottom: 0;
  font-size: 15.5px;
  line-height: 20px;
  vertical-align: middle;
  font-family: 'Sentinel A', 'Sentinel B', serif;
  touch-action: manipulation;
}

.ant-time-picker-panel-clear-btn {
  top: 15px;
  right: 12px;
}

.ant-time-picker-panel-addon {
  padding: 0;
}

.ant-time-picker-panel-addon .header {
  font-size: 1.3em;
}

.ant-time-picker-panel-addon .header > span {
  width: 50%;
  text-align: center;
  display: inline-block;
  border-left: 1px solid #e9e9e9;
  padding: 8px;
}

.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 100%;
  width: 100%;
}

.ant-time-picker-panel-input {
  padding: 7px;
  font-size: 1.3em;
}

.ant-time-picker-icon {
  right: 21px;
}

.ant-time-picker-panel-combobox {
  width: 200px;
}

.ant-time-picker-panel-select {
  width: 100px;
}

.ant-time-picker-panel-select li {
  padding: 7px 0 5px;
  font-size: 1.3em;
  text-align: center;
  width: 100%;
}

.btn-toolbar {
  margin-left: 0;
}

.btn-toolbar > .btn-group {
  margin-left: 0;
}

.dropdown-menu > li > a {
  padding: 10px 20px;
}

.padded-top-xs {
  padding-top: 10px;
}
.padded-top-large {
  padding-top: 28px;
}
.padded-top {
  padding-top: 14px;
}
.padded-bottom {
  padding-bottom: 14px;
}
.padded-bottom-large {
  padding-bottom: 22px;
}

p.bold, span.bold {
  font-weight: 600;
}

p.tag-second {
  margin-bottom: 0 !important;
}

.hide-empty:empty {
  display: none !important;
}

button,
button:focus,
span.Tappable,
span.Tappable-inactive,
span.Tappable-active,
span.Tappable:focus {
  border: none;
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  -webkit-appearance: none;
}

.container {
    width: 100%;
}

.white {
  color: white;
}

.kale {
  color:#3b6142 !important;
}

.darker-kale {
  color: #315738;
}

.linen {
  color:#e8e5e0;
}

.ivory {
  color: #f5f2ed;
}

.grey {
  color: #424242;
}

.darkbrown {
  color: #7D7769;
}

.selected, .selected:focus {
  background-color: #e3efdb !important;
  border: 1px solid #92D27F !important;
}

.nav-tabs {
  display: flex;
  border-bottom: 1px solid transparent;
}

.nav-tabs > li {
  flex: 1;
  float: initial;
}

.nav-tabs > li > a {
  color: #BEBEBE !important;
  display: inline-block;
}

.nav-tabs > li > a:hover {
  background-color: rgba(0, 0, 0, 0);
}

.nav-tabs > li.active > a {
  background-color: rgba(0, 0, 0, 0) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 3px solid #92D27F !important;
  color: #92D27F !important;
}

.nav-tabs > li > a:hover {
  border: 1px solid rgba(0, 0, 0, 0);
}

.tablist {
  font-size: 0.9em;
}

h2 {
  font-size: 5.2em;
}

h3 {
  font-size: 1.5em;
  font-family: 'Sentinel A', 'Sentinel B', serif;
  margin-bottom: 0;
}

h4 {
  font-size: 1.8em;
}

h5 {
  font-size: 1.4em;
}

h2, h4, h5, h2 span, h4 span, h5 span {
  font-family: 'Fjalla One', sans-serif;
}

p, a, button, span.n-text, span {
  font-family: 'Sentinel A', 'Sentinel B', serif;
  font-size: 1em;
}

a {
  color: #ffffff;
}
a:hover, a:focus {
  color: #ffffff;
  text-decoration: none;
  outline: none;
  outline-offset: 0;
}

a.blue-link, button.blue-link {
  color: #0092AC !important;
  font-size: 0.95em;
}

a.blue-link:hover, a.blue-link:focus, button.blue-link:hover, button.blue-link:focus {
  text-decoration: underline !important;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  font-family: 'Sentinel A', 'Sentinel B', serif;
  list-style: none;
}

.content-main {
  text-align: center;
  padding: 14px 25px 0;
  position: relative;
}
.content-main h3 {
  margin-top: 0;
  font-size: 1.15em;
  padding-bottom: 8px;
}
.content-main p {
  font-size: 1em;
}
.content-main p:last-child {
  margin-bottom: 0;
}
.content-mobile {
  text-align: center;
  padding-top: 6px;
  max-width: 90%;
  margin: auto;
}

.alert-text {
  width: 100%;
  font-size: 1em;
  text-align: center;
  color: #D55157;
  margin-top: 13px;
  margin-bottom: 20px;
}
.alert-text .lnr {
  font-size: 1.4em;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

input {
  border: 1px solid #ffffff;
  padding: 12px 20px;
  font-size: 1em;
  font-family: 'Sentinel A', 'Sentinel B', serif;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  color: #3c6142;
}
input:focus {
  outline: none;
  border: 1px solid #ddd2ca;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
}
input.one-row {
  height: 44px;
}
input.bar-half {
  width: 30vw;
  max-width: 361px;
  margin: auto;
}
input.bar-full {
  width: 100%;
  box-sizing: border-box;
}

.btn-toolbar .btn-group label,
.btn-toolbar .btn-group label:hover
.btn-toolbar .btn-group label:active,
.btn-toolbar .btn-group label:active:hover {
  -webkit-appearance: initial;
  border: solid 1px #91B698;
  color: #3B6142;
  background-color: transparent;
  cursor: pointer;
}
.btn-toolbar .btn-group label.active,
.btn-toolbar .btn-group label.active:hover,
.btn-toolbar .btn-group label.active:active,
.btn-toolbar .btn-group label.active:focus,
.btn-toolbar .btn-group label:focus {
  -webkit-appearance: initial;
  border: solid 1px #91B698;
  background-color: #91B698;
  color: #FFFFFF;
  box-shadow: initial;
  cursor: initial;
}

.addComma::after {
  content: ', ';
}

.addComma:last-child::after {
  content: '';
}

.header-image {
  position: relative;
  text-align: center;
  z-index: -100;
}

.center, .center-button {
  margin: 20px auto;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 27px;
}

.switch input {
  display:none;
}

input:checked + .slider {
  background-color: #92D27F;
}

input:focus + .slider {
  box-shadow: 0 0 1px #92D27F;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D6D2CA;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider.round {
  border-radius: 27px;
}

.slider.round:before {
  border-radius: 50%;
}

.panel-default {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.panel-default > .panel-heading {
  background-color: transparent;
  border: none;
}

.panel-default > .panel-heading > .panel-title > a {
  color: #000000;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .padded-top {
    padding-top: 22px;
  }
  .padded-bottom {
    padding-bottom: 22px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  .switch input {
    display:none;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D6D2CA;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: #92D27F;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #92D27F;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  p.tag-second {
    margin-bottom: 5px !important;
  }
}

/* Mobile Landscape and Portrait */
@media (max-width: 767px) {
  p.tag-second {
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .center-button {
    margin: 0px auto;
  }
}

.ant-time-picker-input {
  padding: 22px 12px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #91B698 !important;
  border-radius: 5px;
  box-shadow: none;
  color: #3c6142;
  text-align: left;
  font-size: 14px;
}

.ant-time-picker-panel {
  z-index: 9999999999;
}

input[type=checkbox] {
  visibility: hidden;
}

.checkboxCustom {
  width: 25px;
  margin: 0px;
  position: relative;
}
.checkboxCustom label {
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  top: 0;
  left: 0;
  background: transparent;
  border:2px solid #90B698;
}
.checkboxCustom label:after {
  opacity: 0;
  content: '';
  position: absolute;
  width: 12px;
  height: 7px;
  background: transparent;
  top: 5px;
  left: 5px;
  border: 3px solid #90B698;
  border-top: none;
  border-right: none;

  transform: rotate(-45deg);
}
/**
* Create the hover event of the tick
*/
.checkboxCustom label:hover::after {
  opacity: 0.5;
}

/**
* Create the checkbox state for the tick
*/
.checkboxCustom input[type=checkbox]:checked + label:after {
  opacity: 1;
  border: 3px solid #F8F3EF;
  border-top: none;
  border-right: none;
}
.checkboxCustom input[type=checkbox]:checked + label {
  background: #90B698;
}

/* Mobile Landscape Only*/
@media (max-width: 767px) and (min-width: 480px) {
  .center {
    margin: 10px auto;
  }
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .center {
    margin: 15px auto;
  }
}
