.FavoriteMenuItem {
  display: flex;
  background-color: #F8F3EF;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  margin: 0 14px 14px 0;
  color: #3c6142;
  text-align: left;
  border-radius: 5px;
  overflow: hidden;
}

.FavoriteMenuItem .Image {
  background-color: #ffffff;
  width: 70px;
  min-width: 70px;
  height: auto;
  align-items: top;
  padding: 10px 0;
}

.FavoriteMenuItem .Image img {
  width: 100%;
  height: inherit;
}

.FavoriteMenuItem .Details {
  flex: 1;
  overflow: hidden;
  padding: 4px 8px;
}
.FavoriteMenuItem .Details h5 {
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
}
.FavoriteMenuItem .Details h5 .Name {
  margin-right: 7px;
}
.FavoriteMenuItem .EditName {
  font-size: 0.85em;
  color: #91B698;
}
.FavoriteMenuItem .Details p {
  font-size: 0.7em;
  line-height: 1.5em;
}
.FavoriteMenuItem .Details .AddedIngredientsText {
  font-style: italic;
}
.FavoriteMenuItem .Details .RemovedIngredientsText {
  font-style: italic;
}

.FavoriteMenuItem .Toolbar {
  border-left: 1px solid #E2DFD8;
}

.FavoriteMenuItem .Toolbar .Button {
  width: 60px;
  min-width: 60px;
  height: 100%;
  padding: 5px;
  font-size: 0.9em;
}

.FavoriteMenuItem .Toolbar .Button .lnr {
  font-size: 1.25em;
}

.FavoriteMenuItem.NoButtons .EditName, .FavoriteMenuItem.NoButtons .Toolbar {
  display: none;
}