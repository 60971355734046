.PickupTime {
  width: 100% !important;
}
.PickupTime .Select {
  width: 100% !important;
}
.PickupTime .Select {
  margin-bottom: 10px;
}

.PickupTime .ant-time-picker {
  width: 100%;
}

.ant-time-picker-panel-select-option-disabled {
  display: none;
}

.PickupTime span.instant-order {
  content: url(icon-order-instant.svg) !important;
  width: 14px;
  height: 14px;
  margin-right: 16px;
  position: relative;
  top: 3px;
}
.PickupTime span.scheduled-order {
  content: url(icon-order-schedule.svg) !important;
  width: 14px;
  height: 14px;
  margin-right: 16px;
  position: relative;
  top: 3px;
}
