.NutritionalSection {
  clear: both;
  background-color: #F2EDE9;
  padding: 6px 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: calc(100vh - 45px);
  overflow-y: auto;
}
.NutritionalSection p {
  margin: 15px 0 5px;
  color: #454545;
  font-size: 0.85em;
}

.NutrientsList {
  column-count: 2;
  flex-wrap: wrap;
}
.NutrientsList ul li {
  display: inline-block;
  color: #3c6142;
  font-size: 0.85em;
  padding: 4px 0;
  width: 50%;
}

.NutritionalDisclaimer {
  margin: 10px 0;
  font-size: 0.85em;
  color: #3c6142;
}
.NutritionalDisclaimer span {
  font-style: italic;
  color: #454545;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .NutritionalSection {
    position: initial;
    text-align: left;
    padding: 0 10px;
    overflow: visible;
  }
  .NutritionalSection .content-top {
    margin-left: 0;
  }
  .NutrientsList ul li {
    width: 35%;
    padding: 0;
  }
}
