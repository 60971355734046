.ErrorSticker {
  background-color: #e0cece;
  border: 1px solid #d27f7f;
  border-radius: 5px;
  color: #D85A5B;
  padding: 10px 10px 14px 10px;
  margin-bottom: 18px;
}
.ErrorSticker p {
  margin-bottom: 0;
}
.ErrorSticker p:nth-child(n+2) {
  margin-top: 5px;
}
.ErrorSticker .lnr {
  font-size: 1.5em;
  margin-right: 8px;
  position: relative;
  top: 4px;
}
