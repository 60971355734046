.IngredientModal .modal-dialog {
  width: 80% !important;
  max-width: 778px !important;
}

.IngredientModal .modal-body {
  display: flex;
  text-align: left;
  padding: 0;
  font-size: 0.95em;
}

.IngredientModal .modal-content {
  top: 8vh !important;
  border-radius: 6px !important;
}

.IngredientModal .modal-control {
  position: absolute;
}

.IngredientModal .modal-body p {
  padding-left: 15px;
  margin-top: 8px;
  color: #989898;
  margin-top: 12px;
  margin-bottom: 5px;
}

.IngredientModal .modal-body ul {
  padding-left: 15px
}

.IngredientModal .modal-body ul li {
  display: inline-block;
  padding: 3px 0;
}

.IngredientModal .modal-body ul li:first-child {
  width: 115px;
}

.IngredientModal .modal-body .NutrientsList ul li {
  font-size: 1em;
}

.IngredientModal .IngredientInfo {
  flex: 1;
  padding: 20px 0 20px 15px;
}

.IngredientModal .NutrientsList {
  column-count: 1;
}

.IngredientModal h5 {
  font-size: 1.4em;
  padding-left: 15px;
}

.IngredientModalImage {
  width: 435px;
  background-color: #ffffff;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  overflow: hidden;
}

.IngredientModalImage img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.IngredientModal .modal-body .NutritionalDisclaimer {
  width: 80%;
  color: #3c6142;
  font-style: italic;
  font-size: 0.9em;
  padding-top: 10px;
}

/*--- M1: tablet and mobile ---*/
@media (max-width: 992px) {
  .IngredientModal .modal-dialog {
    width: 100% !important;
    max-width: none !important;
    padding: 0 27.5px;
  }
  .IngredientModal .IngredientInfo {
    padding: 0;
  }
  .IngredientModal .modal-content {
    top: 0vh !important;
  }
  .IngredientModal .modal-body .IngredientModalImage {
    width: 100%;
    height: 20vh;
    background-color: #ffffff;
    overflow: hidden;
    text-align: center;
    margin-bottom: 15px;
  }
  .IngredientModal .modal-body .IngredientModalImage img{
    height: 100%;
    object-fit: cover;
  }
  .IngredientModal .modal-body .IngredientInfo {
    position: relative;
    top: 0;
    left: 0;
    text-align: left;
  }
  .IngredientModal .modal-body .IngredientInfo h5 {
    font-size: 1.3em;
    padding-left: 15px;
  }
  .IngredientModal .modal-body .IngredientInfo p {
    padding-left: 15px;
    margin-top: 8px;
  }
  .IngredientModal .modal-body .IngredientInfo ul {
    padding-left: 15px;
  }
  .IngredientModal .modal-body .IngredientInfo ul li {
    padding: 0;
    font-size: 0.85em;
  }
  .IngredientModal .modal-body .IngredientInfo ul li:nth-child(2) {
    font-weight: 400;
  }
  .IngredientModal .modal-body .NutritionalDisclaimer {
    width: 100%;
    padding-top: 3px;
    padding-bottom: 15px;
    line-height: 1.2em;
    font-size: 0.75em;
  }
}
