.img-cyo {
  background-color: #ffffff;
  width: 100%;
  height: calc(100vh - 331px);
  min-height: 250px;
  text-align: center;
  overflow-x: hidden;
}

.img-cyo img {
  object-fit: cover;
  width: 100%;
  height: auto;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  min-height: calc(100vh - 331px);
}

.cyo-alert-text {
  width: 100%;
  font-size: 1.1em;
  text-align: center;
  color: #D55157;
  margin-top: 13px;
}
.cyo-alert-text span {
  font-size: 1.4em;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.Cyo-1 .CategoryName {
  display: none;
}

.Cyo-2 .CategoryName {
  font-size: 1.2em !important;
  text-align: left;
}

.Cyo-3 .CategoryName {
  display: none;
}

.Cyo-4 .CategoryName {
  display: none;
}

.Cyo-1 .CyoSection .IngredientsPicker {
  padding: 26px 20px 16px;
  justify-content: left;
}

.Cyo-2 .CyoSection .IngredientsPicker {
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
}

.MenuContentFrame .DietButton {
  position: fixed;
  bottom: 69px;
  z-index: 9998;
}

.CyoModal .Ingredients {
  display: inline-flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 10px 0 20px 0;
  width: 100%;
}

/* Tablet and Mobile */
@media (max-width: 992px) {
  .ActionButton li span {
    padding-left: 10px;
    font-weight: 400;
  }
}

/* Tablet Only */
@media (min-width: 768px) and (max-width: 992px) {
  .img-cyo-tablet {
    height: 25vh;
    width: 100vw;
  }
  .img-cyo-tablet img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
