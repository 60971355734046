.LoyaltyRewardsList {
  margin: 0 auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
}

.LoyaltyRewardsList .LoyaltyRewardWrapper {
  margin: 0 10px;
  max-width: 600px;
  width: calc(100% - 40px);
}

.user-container .LoyaltyRewardsList .LoyaltyRewardWrapper {
  max-width: 387px;
}
