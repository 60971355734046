.Reorder .nav-tabs {
  background-color: #F8F3EF;
  display: flex;
  border: none;
}

.Reorder .nav-tabs>li.active>a {
  border-bottom: 5px solid #3B6142 !important;
  padding: 15px 15px 11px 15px;
}

.Reorder .nav-tabs>li>a {
  color: #3B6142 !important;
  width: 100%;
  padding: 15px;
  text-align: center;
}

.Reorder .tab-content {
  padding: 15px;
}
