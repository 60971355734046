.cart .cart-body .CartCheckout .TimePicker {
  display: flex;
}

.cart .cart-body .CartCheckout .TimePicker .SelectWrapper {
  flex: 1;
}

.cart .cart-body .CartCheckoutt .TimePicker .SelectWrapper::after {
  display: none;
}

.cart .cart-body .CartCheckout .TimePicker .Select {
  appearance: none;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #91B698 !important;
  border-radius: 5px;
  box-shadow: none;
  color: #3c6142;
  text-align: left;
}
.cart .cart-body .CartCheckout .TimePicker #TimePickerHours {
  margin-right: 5px;
}
.cart .cart-body .CartCheckout .TimePicker #TimePickerMinutes {
  margin-left: 5px;
}
