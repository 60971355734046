.Order li.column>div {
  margin-bottom: 8px;
}

.Order .FeedbackSticker {
  width: calc(100% - 20px) !important;
  margin: 10px;
}

.Order .time, .Order .order{
  margin-top: 45px;
}
.Order .time-confirmed, .Order .time-cancelled {
  margin-top: 15px;
}

.Order .time img {
  width: 20px;
  margin-right: 8px;
}
.Order .time p {
  width: 50%;
  font-size: 1.1em;
  margin: 0 auto;
}
.Order .code h3 {
  font-size: 2.3em;
  margin-top: 5px;
}
.Order .order {
  margin-bottom: 100px;
}
.Order .order>ul:first-child {
  position: relative;
  text-align: center;
  width: 80%;
  max-width: 480px;
  margin: 0 auto;
}
.Order .order>ul:first-child p {
  font-size: 0.95em;
  color: #7D7769;
}
.Order .order>ul li {
  display: inline-block;
  vertical-align: text-top;
}
.OrderItems {
  width: 80%;
  max-width: 480px;
  display: block;
  margin: 0 auto 10px;
  text-align: left;
}
.Order .cancel {
  text-align: center;
  width: 100%;
  margin: 0px auto;
}
.Order .cancel p {
  color: #7D7769;
  font-size: 0.8em;
  margin-top: 8px;
}
.Order .time-confirmed>ul, .Order .time-cancelled>ul {
  padding-top: 10px;
}
.Order .time-confirmed>ul li {
  display: inline-block;
  font-size: 1.3em;
}
.Order .time-confirmed>ul li:first-child {
  text-align: right;
  padding-right: 35px;
}
.Order .time-confirmed>ul li:last-child {
  text-align: left;
  padding-left: 35px;
}
.Order .time-confirmed>ul li span{
  font-weight: 600;
}
.Order .reorder {
  text-align: center;
}
.Order .Disclaimer {
  margin-top: 25px;
  font-size: 13.75px;
}


/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .Order .time, .Order .order{
    margin-top: 45px;
  }
  .Order .time p {
    width: 80%;
  }

  .Order .store .store-info {
    width: 85%;
    max-width: 360px;
  }

  .Order .order>ul:first-child {
    width: 100%;

  }
  .Order .OrderItems {
    width: 90%;
    max-width: 400px;
  }
  .Order .cancel {
    margin: 20px auto;
  }
}

/*--- Mobile Landscape and Portrait --*/
@media (max-width: 767px) {
  .Order .time{
    margin-top: 28px;
  }
  .Order .order {
    margin-top: 0px;
  }
  .Order .time img {
    width: 16px;
    position: relative;
    top: -2px;
  }
  .Order .time p {
    width: 80%;
  }

  .Order .store .store-info {
    width: 90%;
    max-width: 440px;
  }
  .Order .code h3 {
    font-size: 2em;
  }
  .Order .order {
    margin-bottom: 100px;
  }
  .Order .OrderItems {
    padding-top: 15px;
    width: 90%;
  }
  .Order .cancel {
    margin: 15px auto;
    padding: 0 0 40px 0;
  }
  .Order .cancel button {
    margin-top: 20px;
  }
}


/* Mobile Portrait Only */
@media (max-width: 479px) {
  .Order .time-confirmed>ul {
    width: 80%;
    margin: auto;
    font-size: 1.0em;
    padding: 20px 0 10px 0;
  }
  .Order .time-confirmed>ul li {
    width: 49%;
  }

  .Order .time-confirmed>ul li:first-child {
    text-align: left;
    padding-right: 0;
  }
  .Order .time-confirmed>ul li:last-child {
    text-align: right;
    padding-left: 0;
  }
}
