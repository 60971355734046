.IngredientsPicker {
  margin: 0 auto;
  padding: 26px 30px 0;
  position: relative;
  display: block;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.CustomizeRight .DietButton {
  position: fixed;
  bottom: 69px;
  z-index: 9998;
}

.CustomizeRight .DietSticker {
  position: absolute;
  bottom: 74px;
  z-index: 9998;
}

.MenuDetailsAdd.MenuContentFrame {
  padding-top: 0;
  padding-bottom: 0;
}

.MenuDetailsAdd.MenuContentFrame .content-main h3 {
  margin-top: 0;
}

.MenuDetailsAdd .MenuDetailsAddBody {
  max-height: calc(100vh - 45px - 54px);
  width: 100%;
  overflow-y: auto;
}

.MenuDetailsAdd.isHeaderShown .MenuDetailsAddBody {
  max-height: calc(100vh - 45px - 54px - 74px);
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .CustomizeRight .DietSticker {
    position: fixed;
  }
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .MenuDetailsAdd.isHeaderShown .MenuDetailsAddBody {
    max-height: calc(100vh - 45px - 54px - 54px);
  }

  /*
    iOS devices only.
    100vh on iOS is not equivalent to the full available viewport, instead it includes 75px high
    navigation bars on both sides.
  */
  @supports (-webkit-overflow-scrolling: touch) {
    .App:not(.isWebView) .MenuDetailsAdd.isHeaderShown .MenuDetailsAddBody {
      max-height: calc(100vh - 45px - 54px - 54px - 75px);
    }
  }

  .MenuDetailsAdd .MenuDetailsAddBody {
    -webkit-overflow-scrolling: touch;
    padding-bottom: 80px;
  }
}
