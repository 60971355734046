.ToastWrapper {
  display: none;
  position: fixed;
  top: 176px;
  right: 0;
  z-index: 9999;
  text-align: center;
  width: calc(100% - 46vw);
  opacity: 0;
}

.ToastWrapper.slideInUp {
  display: block;
  opacity: 1;
}

.Toast {
  background-color: #3c6142;
  padding: 10px 10px;
  color: #fff;
  text-align: center;
  font-size: 1em;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  display: inline-block;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .ToastWrapper {
    left: 0;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .ToastWrapper {
    top: 110px;
  }
  .Toast {
    width: 90vw;
  }
}
