.IngredientAddWrapper {
  display: flex;
  flex-direction: column;
  width: 90px;
}
.IngredientAdd {
  margin-bottom: 10px;
  border: 1px solid #a8b3a4;
  border-radius: 5px;
  text-align: center;
  height: 136px;
  flex: 1;
}
.IngredientAdd:hover, .IngredientAdd:focus {
  background-color: rgba(168, 179, 164, 0.25);
  border: 1px solid #a8b3a4;
}
.IngredientAdd img{
    width: 38px;
    padding: 30% 0 10px 0;
}
.IngredientAddCaption {
  font-size: 0.7em;
  width: 40px;
  margin: auto;
  color: #3c6142;
  line-height: 1.5em;
}

@media (max-width: 992px) {
  .IngredientAddWrapper {
    width: 30%;
    max-width: 115px;
  }
}
