/* .item */
.MenuItem {
  width: 18vw;
  max-width: 192px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
  margin: 5px;
  visibility: hidden;
  background-color: #f5f2ed;
  position: relative;
}

.MenuItem:hover {
  cursor: pointer;
}

.MenuItem a {
  width: 100%;
}

.MenuItem .menu-image img {
  width: 100%;
  height: 18vw;
  max-height: 192px;
}

.menu-desc {
  background-color: #f5f2ed;
  color: #3c7162;
  text-align: left;
  padding: 3px 12px 3px 12px;
  width: 100%;
  padding-bottom: 30px;
}
.menu-desc h5 {
  font-size: 1.05em;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  max-width: 98%;
  overflow: hidden;
  line-height: 1.3em;
}
.menu-desc .PriceWrapper {
  position: absolute;
  bottom: 0px;
  left: 12px;
}
.menu-desc .Price {
  color: #3c7162;
}
.menu-desc .From {
  font-size: 0.8em;
  font-style: italic;
  color: #95989A;
  padding-right: 5px;
}
.menu-desc .EnergyWrapper {
  position: absolute;
  bottom: 0px;
  right: 12px;
}
.menu-desc .Energy {
  color: #95989A;
  font-size: 0.8em;
}
.menu-desc ul li {
  display: inline-flex;
}

.MenuItem .PromoFlag {
  font-size: 0.75em;
  color: #fff;
  padding: 3px 7px;
  position: absolute;
  top: 0;
  left: 0;
}

/* Mobile Landscape */
@media (max-width: 767px) and (min-width: 480px) {
  /* .item */
  .MenuItem {
    width: 29vw;
    max-width: none;
    margin: 4px;
  }

  .MenuItem .menu-image img {
    height: 29vw;
    max-height: none;
  }
}

/* Mobile Portrait */
@media (max-width: 479px) {
  .MenuItem {
    width: 44vw;
    max-width: none;
    margin: 3px;
  }

  .MenuItem .menu-image img {
    height: 44vw;
    max-height: none;
  }
}
