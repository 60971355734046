/* .shop-info.content-main */
.GglocationInfo p, .GglocationInfo a {
  font-size: 1em;
  width: 90%;
  margin: 0 auto 5px auto;
  font-size: 0.95em;
}

.GglocationInfo.content-main {
  padding: 14px 25px;
}

.GglocationInfo p:nth-child(3){
  margin-bottom: 15px;
}

/* .shop-info .shop-add */
.GglocationInfo p.gglocation-address::before{
  content: url(./icon-distance.svg);
  margin-right: 7px;
  position: relative;
  top: 3px;
}

/* Tablet and Mobile */
@media (max-width: 992px) {
  /* .shop-info */
  .GglocationInfo {
    position: relative;
  }

  /* .shop-info .shop-add */
  .GglocationInfo  p.gglocation-address {
    margin: 10px auto 12px auto;
    width: 100%;
    position: relative;
    font-size: 0.95em;

  }
}
