.HighlightedRewardModal.modal .modal-control {
  position: absolute;
}

.HighlightedRewardModal.modal .modal-content {
  overflow: hidden;
}

.HighlightedRewardModal .RewardImage {
  height: 200px;
  max-width: 100%;
  background-color: #FFFFFF;
  text-align: center;
}

.HighlightedRewardModal .RewardImage img {
  height: 200px;
  max-width: 100%;
}

.HighlightedRewardModal.modal .modal-dialog {
  width: 100% !important;
  max-width: none !important;
  padding: 0 27.5px;
}

.HighlightedRewardModal.modal .modal-body {
  text-align: left;
  padding: 0;
}

.HighlightedRewardModal .HighlightedRewardModalSection {
  border-bottom: solid 1px #FFFFFF;
  padding: 15px;
}

.HighlightedRewardModal .panel-heading, .HighlightedRewardModal .panel-body {
  padding: 0;
}

.HighlightedRewardModal .panel-body {
  padding-top: 15px;
}

.HighlightedRewardModal .panel-default {
  margin-bottom: 0;
}

.HighlightedRewardModal .panel-default > .panel-heading > .panel-title > a {
  color: #3b6142;
}

.HighlightedRewardModal .TCSection {
  white-space: pre-line;
}

.HighlightedRewardModal .RedeemSection {
  text-align: center;
}

/*--- Tablet and Desktop ---*/
@media (min-width: 768px) {
  .HighlightedRewardModal.modal .modal-body {
    display: flex;
  }

  .HighlightedRewardModal.modal .modal-dialog {
    width: 80% !important;
    max-width: 778px !important;
  }

  .HighlightedRewardModal .RewardImage, .HighlightedRewardModal .RewardImage img {
    height: initial;
    width: 350px;
    flex: 1;
    max-width: initial;
  }

  .HighlightedRewardModal .RewardInfo {
    display: flex;
    flex-direction: column;
  }

  .HighlightedRewardModal .RedeemSection {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}
