.Counter {
  background-color: #fff;
  height: 28px;
  text-align: center;
  padding: 4px 0;
}
.Decrement {
  position: absolute;
  left: 0;
  padding-left: 12px;
  top: 0;
  bottom: 0;
  width: 50%;
  text-align: left;
}
.Increment {
  position: absolute;
  right: 0;
  padding-right: 12px;
  top: 0;
  bottom: 0;
  width: 50%;
  text-align: right;
}
