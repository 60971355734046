/* img-store */
.ImageStore {
  width: 63vw;
  height: calc(100vh - 45px);
  border-right: 1px solid #ffffff;
  float: left;
}
.ImageStore img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

/* .content-container */
.ContentContainer {
  position: relative;
  width: calc(100% - 63vw);
  float: right;
  margin: auto;
  height: calc(100vh - 45px);
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
}

/* Tablet and Mobile */
@media (max-width: 992px) {
  /* .content-container */
  .ContentContainer {
    width: 100%;
    float: none;
    padding: 0;
    position: relative;
    min-height: calc(100vh - 23vh - 45px);
    max-height: 100%;
    height: auto;
    overflow-y: auto !important;
  }

  ul.picker-time li {
    display:inline-flex;
    width: 47.3%;
    height: 52px;
    margin: 8px 1%;
  }

  /* #img-store */
  .ImageStore {
    width: 100vw;
    height: 23vh;
    border-right: none;
    position: relative;
  }

  .shop-info p.shop-add {
    margin: 10px auto 12px auto;
    width: 100%;
    position: relative;
    font-size: 0.95em;
  }
}
