.NutritionalBlock {
  display: flex;
  flex-wrap: wrap;
  padding: 6px 30px;
}

.NutritionalBlock #NutritionalTabs {
  padding: 10px 0 20px;
  border-bottom: 0;
}

.NutritionalBlock #NutritionalTabs li {
  margin: 0 10px;
}

.NutritionalBlock #NutritionalTabs li:first-child {
  margin-left: 0;
}

.NutritionalBlock #NutritionalTabs li:last-child {
  margin-right: 0;
}

.NutritionalBlock #NutritionalTabs li a {
  padding: 0;
  margin: 0;
}

.NutritionalBlock.hideTabs .CaloriePercent {
  text-align: left;
}

.NutritionalBlock .CaloriePercent {
  flex-grow: 1;
  text-align: right;
  padding: 10px 0 20px;
}

.NutritionalBlock .CaloriePercent * {
  text-align: left;
}

.NutritionalBlock .NutrientsList {
  width: 100%;
}

/* Mobile Portrait */
@media (max-width: 480px) {
  .NutritionalBlock {
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    background-color: #91B698;
  }

  .NutritionalBlock .NutrientName,
  .NutritionalBlock .NutrientUnit {
    color: #C0D5C5;
  }

  .NutritionalBlock .NutrientValue {
    font-weight: bold;
    color: #FCFEFC;
    padding-left: 5px;
  }

  .NutritionalBlock #NutritionalTabs,
  .NutritionalBlock .CaloriePercent,
  .NutritionalBlock .NutritionalDisclaimer,
  .NutritionalBlock .NutrientsList > ul {
    display: none;
  }

  .NutritionalBlock .NutrientsList > ul li {
    width: initial;
  }

  .NutritionalBlock .NutrientsList > ul.visibleOnMobile {
    display: initial;
  }

  .CyoDetails.kale {
    display: none;
  }

  .NutritionalBlockDisplayNone {
    display: none;
  }
}

@media (max-width: 330px) {
  .NutritionalBlock {
    height: 68px;
  }

  .NutritionalBlock .NutrientsList,
  .NutritionalBlock .NutrientsList ul.visibleOnMobile {
    display: flex;
    justify-content: center;
    column-count: 1;
  }

  .NutritionalBlock .NutrientsList ul.visibleOnMobile {
    width: 100%;
  }

  .MenuDetails .MenuDetailsAddHeader .CategoryChoice .CategoryPaginationBar,
  .Cyo .MenuDetailsAddHeader .CategoryChoice .CategoryPaginationBar {
    margin-top: 68px;
  }

  .content-main.kale {
    padding-top: 68px;
  }

  .NotPremadeItem {
    margin-top: 68px;
  }
}

@media (min-width: 331px) and (max-width: 480px) {
  .NutritionalBlock {
    height: 32px;
  }

  .NutritionalBlock .NutrientsList {
    display: flex;
    justify-content: space-between;
    column-count: 1;
  }

  .MenuDetails .MenuDetailsAddHeader .CategoryChoice .CategoryPaginationBar,
  .Cyo .MenuDetailsAddHeader .CategoryChoice .CategoryPaginationBar {
    margin-top: 32px;
  }

  .content-main.kale {
    padding-top: 32px;
  }

  .NotPremadeItem {
    margin-top: 32px;
  }
}
