.Ingredient {
  width: 90px;
  margin-right: 10px;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
  background-color: #f5f2ed;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  touch-action: manipulation;
}
.Ingredient.removeable:hover {
  cursor: pointer;
}
.Ingredient.replaceable:hover {
  cursor: pointer;
}
.Ingredient.removeable:focus::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #D55157;
}
.Ingredient.replaceable:focus::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #92D27F;
}
.Ingredient.removeable:focus::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #D55157;
}
.Ingredient.disabled::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e8e5e0;
}
.Ingredient.resolved::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e8e5e0;
}
.Ingredient.selected {
  background-color: #f5f2ed !important;
  border: initial !important;
}
.Ingredient.selected::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #92D27F;
}

.Ingredient.removeable:focus::after {
  content: url(icon-remove.svg) ;
  position: absolute;
  top: 12%;
  left: 25%;
  width: 50%;
  height: auto;
}
.Ingredient.replaceable:focus::after {
  content: url(icon-change.svg) ;
  position: absolute;
  top: 12%;
  left: 28%;
  width: 50%;
  height: auto;
}
.Ingredient.disabled::after {
  content: "Removed";
  position: absolute;
  font-size: 0.95em;
  font-family: 'Sentinel A', 'Sentinel B', serif;
  top: 30%;
  left: 0%;
  width: 100%;
  text-align: center;
  color: #3c6142;
}
.Ingredient.resolved::after {
  content: "Filtered";
  position: absolute;
  font-size: 0.95em;
  font-family: 'Sentinel A', 'Sentinel B', serif;
  top: 30%;
  left: 0%;
  width: 100%;
  text-align: center;
  color: #3c6142;
}
.Ingredient.selected::after {
  content: url(icon-added.svg) ;
  position: absolute;
  top: 45%;
  transform: translateY(-70%);
  left: 28%;
  width: 50%;
  height: auto;
}

.Ingredient:focus {
  outline: none;
}
.Ingredient.removeable:focus .IngredientImage::after {
  content: "";
  width: 100%;
  padding-top: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(213,81,87,0.65);
}
.Ingredient.replaceable:focus .IngredientImage::after {
  content: "";
  width: 100%;
  padding-top: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(47,121,58,0.40);
}
.Ingredient.selected .IngredientImage:after {
  content: "";
  width: 100%;
  padding-top: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(47,121,58,0.40);
}
.Ingredient.removeable .IngredientRemove {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 9px;
  background-image: url('./icon-remove-small.svg');
  z-index: 1000;
}
.Ingredient.replaceable .IngredientReplace {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 9px;
  height: 9px;
  background-image: url('./icon-swap-small.svg');
  z-index: 1000;
}
.Ingredient.removeable:focus .IngredientRemove, .Ingredient.Label.removeable .IngredientRemove,
.Ingredient.replaceable:focus .IngredientReplace, .Ingredient.Label.replaceable .IngredientReplace {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: initial;
  height: initial;
  background-image: initial;
  padding: 0;
}
.Ingredient.removeable:focus .IngredientRemove > span, .Ingredient.Label.removeable .IngredientRemove > span,
.Ingredient.replaceable:focus .IngredientReplace > span, .Ingredient.Label.replaceable .IngredientReplace > span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: initial;
  height: initial;
  padding: 0;
  z-index: 1001;
}

.IngredientImage {
  width: 100%;
  display: block;
}
.IngredientImage img {
  width: 100%;
  object-fit: cover;
  height: auto;
}

.IngredientName {
  font-size: 0.85em;
  text-align: center;
  padding: 8px 5px 0 5px;
  display: block;
  /*height: calc(100% - 90px);*/
  position: relative;
  height: auto;
}
.IngredientName p {
  position: relative;
  line-height: 17px;
}

.disabled .IngredientImage img, .disabled .IngredientName, .resolved .IngredientImage img, .resolved .IngredientName {
  opacity: 0.3;
  position: relative;
}

.Ingredient .Servings {
  color: #ffffff;
  background-color: #92D27F;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 3px;
  font-size: 0.9em;
}

.Ingredient.Label {
  display: inline;
  background-color: #E2DFD8;
  padding: 10px 30px 10px 10px;
  border-radius: 6px;
  margin: 3px 6px 3px 0;
  position: relative;
  width: auto;
  max-width: initial;
}

.Ingredient.Label .IngredientImage {
  display: none;
}

.Ingredient.Label .IngredientName {
  font-size: 1em;
  padding: 0;
}

.Ingredient.Label .IngredientName p {
  font-size: 0.9em;
  white-space: nowrap;
  display: inline;
  margin: 0;
}

.Ingredient.Label.removeable::before {
  border: 0;
}

.Ingredient.Label.removeable::after {
  content: '';
  background: url(icon-remove-small.svg);
  top: calc(50% - 4.5px);
  width: 9px;
  height: 8px;
  position: absolute;
  right: 12px;
  left: initial;
  cursor: pointer;
}

.Ingredient.Label.replaceable::after {
  content: '';
  background: url(icon-swap-small.svg);
  top: calc(50% - 4.5px);
  width: 9px;
  height: 8px;
  position: absolute;
  right: 12px;
  left: initial;
  cursor: pointer;
}

.Ingredient.Label .Servings {
  background-color: #3C6142;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .Ingredient {
    width: 30%;
    max-width: 115px;
    margin: 0 12px 12px 0;
  }
}

/*--- Mobile Portrait Only ---*/
@media (max-width: 479px) {
  .Ingredient:nth-child(3n) {
    margin: 0 0 12px 0;
  }
  .Ingredient.Small {
    width: calc((100% - 32px) / 4);
    max-width: 115px;
    margin: 0 10px 12px 0;
  }
  .Ingredient.Small:nth-child(3) {
    margin: 0 10px 12px 0 !important;
  }
  .Ingredient.Small:nth-child(4) {
    margin: 0 0px 12px 0 !important;
  }
  .Ingredient.Label:nth-child(3n) {
    margin: 3px 6px 3px 0;
    padding: 10px 30px 10px 10px;
  }
}
