/* Tablet and Mobile */
@media (max-width: 992px) {
  /* .store-map-mb */
  .GglocationMapMobileWrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    outline: none;
  }

  .GglocationMapMobileWrapper.expanded .GglocationMapMobile {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .GglocationMapMobileCloseButtonWrapper {
    display: none;
    width: 40px;
  }

  .GglocationMapMobileWrapper.expanded .GglocationMapMobileCloseButtonWrapper {
    display: block;
    position: fixed;
    top: 14px;
    left: 5px;
    z-index: 9999999;
    background: #3b6142;
    color: white;
    font-size: 1.8em;
  }
}
