.ContentSeparator ul li {
  display: inline-block;
}
.ContentSeparator {
  position: relative;
  text-align: center;
  margin-bottom: -20px;
  width: 100%;

}
.ContentSeparator p {
  background-color: #e8e5e0;
  position: relative;
  top: -24px;
  padding: 0 12px;
  font-size: 0.95em;
  color: #7D7769;
}
.ContentSeparator .line {
  border-top: 1px solid #ffffff;
  margin-top: 20px;
  height: 10px;
  width: 100%;
}
