.CustomizeRight {
  width: calc(100% - 46vw);
  height: calc(100vh - 45px);
  float: left;
  padding: 0;
  position: relative;
}

.CustomizeRight .section {
  text-align: left;
  padding: 2px 20px;
}
.CustomizeRight .section ul:first-child li {
  display: inline-block;
}

.MenuContentFrame {
  height: calc(100vh - 110px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.ActionButton .btn-next, .ActionButtonCyo .btn-next {
  width: 100%;
  background-color: #3c6142;
  color: #FFF;
  height: 54px;
  position: absolute;
  bottom: 0;
  font-size: 1em;
  transform: translate3d(0, 0, 0);
}

.ActionButtonCyo .btn-next {
  color: #FFF;
}

.ActionButtonCyo .btn-energy {
  color: #FFF;
}

.MenuContentFrame .ActionButton .DietSticker {
  position: absolute;
  bottom: 74px;
  left: 20px;
}
.ActionButton .btn-next .AttentionSeeker, .ActionButtonCyo .btn-next .AttentionSeeker {
  font-weight: 500;
}

.ActionButton .btn-next:hover {
  background-color: #315738;
}

.ActionButton .AttentionSeeker, .ActionButtonCyo .AttentionSeeker {
  padding-left: 10px;
}

.ActionButton .btn-next {
  display: flex;
  align-items: center;
}

.ActionButton .btn-next>span {
  flex: 1;
  text-align: center;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .CustomizeRight {
    width: 100%;
    height: auto;
    float: none;
  }
  .MenuContentFrame {
    height: auto;
    overflow-y: auto;
  }
  .CustomizeRight .section {
    position: relative;
  }
  .CustomizeRight .section h5 {
    font-size: 1.3em;
  }
  .CustomizeRight .section li.icon-nutritional {
    margin-left: 10px;
    position: relative;
    top: -5px;
    left: 5px;
  }
  .CustomizeRight .section li.icon-nutritional ul li {
    width: 28px;
  }
  .CustomizeRight .section ul:nth-child(n+2) li.MenuCategory {
    width: 30%;
    max-width: 115px;
    margin: 0 12px 12px 0;
  }

  .CustomizeRight .section ul:nth-child(n+2) li.btn-add {
    width: 30%;
    max-width: 115px;
    margin: 0 0 12px 0;
    min-height: 150px;
  }

  .CustomizeRight .section ul:nth-child(n+1) li .item-name {
    height: auto;
    position: relative;
    padding-bottom: 0;
  }
  .CustomizeRight .section ul:nth-child(n+1) li .item-name p {
    transform: none;
    padding-top: 5px;
    padding-bottom: 0;
  }
  .CustomizeRight .section ul.dressing {
    position: relative;
    width: 100%;
  }

  .CustomizeRight .section ul.dressing li:nth-child(2) {
    position: absolute;
    right: -10px;
    top: -13px;
    text-align: right;
  }

  .CustomizeRight .section ul.dressing label {
    position: relative;
    top: 16px;
    margin-right: 6px;
  }
  .ActionButton .btn-next {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  .MenuContentFrame .ActionButton .DietSticker {
    position: fixed;
    bottom: 74px;
    z-index: 9999;
  }
  .ActionButton, .ActionButtonCyo {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #F2EDE9;
    font-size: 1em;
    height: 54px;
    text-align: center;
    z-index: 9999;
     -webkit-box-shadow: 0px -1px 2px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px -1px 2px 0px rgba(0,0,0,0.25);
    box-shadow: 0px -1px 2px 0px rgba(0,0,0,0.25);
  }
  .ActionButtonCyo ul{
     width: 100%;
     height: 100%;
  }

  .ActionButtonCyo ul li {
    display: inline-block;
    height: 100%;
  }
  .ActionButtonCyo ul li:first-child {
    width: 45%;
    position: absolute;
    left: 0;
  }
  .ActionButtonCyo ul li:last-child {
    width: 55%;
    position: absolute;
    right: 0;
  }
  .ActionButtonCyo ul li:last-child .Button {
    left: 0;
  }
  .ActionButtonCyo .Button.btn-energy {
    background-color: #91B698;
    height: 100%;
    width: 100%;
    position: relative;
    text-align: center;
    padding: 0 10px;
  }
  .ActionButtonCyo .Ingredients .lnr {
    display: inline;
    position: absolute;
    right: 10px;
    top: 20px;
  }
  .ActionButtonCyo .Button.btn-next {
    background-color: #3C6142;
    height: 100%;
    width: 100%;
    border-left: 2px solid #F2EDE9;
  }
  .ActionButtonCyo .Button.btn-next:hover {
    background-color: #315738;
  }
  .ActionButtonCyo .Button.btn-energy:hover {
    background-color: #819A9F;
  }
  .ActionButtonCyo li span {
    font-weight: 400;
  }
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .CustomizeRight .section ul:nth-child(n+2) li.MenuCategory:nth-child(3n) {
    margin: 0 0 12px 0;
  }
}
