.DietModal > .modal-dialog {
  width: 588px;
}
.DietModal .diet-support-list {
  width: 100%;
  margin: 22px 0 15px 0;
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px){
  .DietModal > .modal-dialog {
    width: 90%;
    margin: auto;
  }
  .DietModal .diet-support-list {
    margin: 10px 0;
  }
  .DietModal .btn-primary {
    width: 50%;
    margin-bottom: 12px;
  }
}

/* Mobile Portrait Only*/
@media (max-width: 479px) {
  .DietModal > .modal-dialog {
    width: 90%;
    margin: auto;
  }
  .DietModal ul.diet-support-list {
    margin: 18px 0;
  }
  .DietModal .btn-primary {
    width: 70% !important;
    margin-bottom: 16px;
  }
}
