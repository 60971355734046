.DietSticker {
  display: block;
  position: fixed;
  bottom: 20px;
  left: 20px;
  border-radius: 10px;
  background-color: #FA5168;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
  padding: 8px 10px 0 10px;
  color: #ffffff;
  z-index: 9999;
  padding: 10px 8px;
}
.DietSticker:focus {
  background-color: #FA5168;
  color: #ffffff;
}
.DietSticker span {
  vertical-align: middle;
}
.DietSticker span.remove-diet {
  display: inline-block;
  width: 20px;
  height: 18px;
  background-image: url(./btn-close.svg);
  color: #FA5168;
  zoom: 65%;
  margin-right: 10px;
}
