.SelectedRewardWrapper {
  max-width: 600px;
  margin: 0 auto 20px;
}

.SelectedReward {
  margin: 0 20px;
  display: flex;
}

.SelectedReward .Title {
  flex: 1;
}
