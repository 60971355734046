.TwoColumns {
  display: inline-flex;
  flex-direction: row-reverse;
  width: 100%;
  margin: 0 auto;
}
.TwoColumns li.column {
  width: 50%;
  text-align: center;
}
.TwoColumns li.column:first-child>div {
  width: 100%;
  float: left;
  clear: both;
}
.TwoColumns li.column:last-child>div {
  width: 100%;
  float: right;
  clear: both;
}
.TwoColumns li.column:first-child {
  border-left: 1px solid #ffffff;
  min-height: calc(100vh - 45px);
}


/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .TwoColumns {
    display: block;
  }
  .TwoColumns li.column {
    width: 100%;
  }
  .TwoColumns li.column:first-child>div {
    float: none;
  }
  .TwoColumns li.column:last-child>div {
    float: none;
  }
  .TwoColumns li.column:first-child {
    border-left: none;
    min-height: 10px;
  }
}
