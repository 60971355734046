.HomeButton {
  display: none;
}

.HomePage .BackButton {
  display: none;
}

.SignInPage .UserButton {
  display: none;
}

.SignInPage .CartButton {
  display: none;
}

.SignInPage.PhonePage .CartButton {
  display: initial;
}

.AccountPage .UserButton {
  display: none;
}
.AccountPage .BackButton {
  display: none;
}
.AccountPage .HomeButton {
  display: initial;
}


.OrderPage .HomeButton {
  display: initial;
}
.OrderPage .BackButton {
  display: none;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .loggedIn .UserButton {
    display: none;
  }

  .HomePage:not(.loggedIn) .CartButton {
    display: none;
  }

  .ShoppingPage .UserButton {
    display: none;
  }

  .AccountPage .HomeButton {
    display: none;
  }

  .OrderPage.loggedIn .UserButton {
    display: initial;
  }
  .OrderPage .CartButton {
    display: none;
  }

  .LocationsPage:not(.loggedIn) .CartButton {
    display: none;
  }
}
