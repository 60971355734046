.NameFavorite .modal-dialog {
  width: 420px;
}
.NameFavorite.modal .modal-dialog .modal-body {
  padding: 25px;
}
.NameFavorite .modal-content {
  background-color: #FAFAF5;
}
.NameFavorite .modal-content p {
  padding-bottom: 6px;
}
.NameFavorite .modal-body>ul {
  margin-bottom: 15px;
}
.NameFavorite .modal-input {
  margin-bottom: 20px;
  width: 95%;
  margin-top: 7px;
}
.NameFavorite .modal-body>.Button {
  width: 80%;
}
.NameFavorite .OrderItem>ul>li:nth-child(2) p {
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .NameFavorite .modal-dialog {
    width: 100vw;
    margin: 50px 0 0 0;
  }
  .NameFavorite .modal-content {
    max-width: 360px !important;
  }
}
