.PersonalizeOrderItem {
  margin-bottom: 10px;
}

.PersonalizeForm {
  padding: 5px 20px;
  width: 100%;
}
.PersonalizeForm p {
  font-size: 1em;
  color: #7D7769;
  margin-bottom: 8px;
  padding-left: 8px;
}
.PersonalizeForm ul {
  display: flex;
  width: 100%;
  max-width: 500px;
}
.PersonalizeForm ul li {
  display: inline-block;
  width: 49%;
  margin: 5px 0;
}
.PersonalizeForm .dropdown-toggle {
  width: 100%;
}
.PersonalizeForm ul.dropdown-menu li {
  width: 100%;
}
.PersonalizeForm ul.dropdown-menu {
  z-index: 1000000;
}
.PersonalizeForm ul li.left {
  margin-right: 1%;
}
.PersonalizeForm input {
  width: 100%;
  max-width: 500px;
}
.PersonalizeForm .dropdown {
  width: 100%;
}

.dropdown button, .order-info {
  padding: 12px !important;
  background-color: transparent !important;
  border: 1px solid #91B698 !important;
  border-radius: 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #3c6142;
  text-align: left;
}
.order-info:focus, .dropdown button:focus {
  outline: none;
}
.order-info span.caret, .dropdown button span.caret {
  color: #91B698;
  position: absolute;
  right: 20px;
  top: 20px;
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}
.order-info::-webkit-input-placeholder {
  color: #91B698;
  text-align: left;
}
.order-info::-moz-placeholder {
  color: #91B698;
  text-align: left;
}

.order-info:-ms-input-placeholder {
  color: #91B698;
  text-align: left;
}
.order-info:-moz-placeholder {
  color: #91B698;
  text-align: left;
}

.PersonalizeForm ul li:nth-child(odd) {
  margin-right: 2%;
}

.PersonalizeBase-2 .PersonalizeChopping {
  width: 100%;
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px){
  .PersonalizeForm {
    max-width: 600px;
    margin: auto;
  }
  .PersonalizeForm ul {
    width: 100%;
    position: relative;
    max-width: 600px;

  }
  .PersonalizeForm ul li {
    width: 48%;
  }
  .PersonalizeForm ul li.PersonalizeAdditions {
    margin-right: 0;
  }
  .PersonalizeForm ul li.PersonalizeChopping {
    position: absolute;
    right: 0;
  }
  .PersonalizeForm input {
    width: 100%;
    max-width: 100%;
  }
  .PersonalizeForm .Select {
    width: 100%;
    max-width: none;
  }
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .PersonalizeForm {
      max-width: 600px;
      margin: auto;
  }
  .PersonalizeForm ul {
      width: 100%;
      position: relative;
      max-width: 600px;
  }
  .PersonalizeForm ul li {
      width: 48%;
  }
  .PersonalizeForm ul li.left {
      margin-right: 0;
  }
  .PersonalizeForm ul li.right {
      position: absolute;
      right: 0;
  }
  .PersonalizeForm input {
      width: 100%;
      max-width: 100%;
  }
  .PersonalizeForm .Select {
      width: 100%;
      max-width: none;
  }
}
