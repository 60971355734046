.MenuItemCard li {
  overflow: hidden;
  transition: .5s ease-out;
}

.MenuItemCard:focus {
  outline: 0;
}

.MenuItemCard:focus > li {
  transform: translateX(-140px);
}

.MenuItemCard .ingredientChanges {
  display: block !important;
  white-space: pre-wrap;
}

.MenuItemCard.premade:focus li {
  transform: translateX(-70px);
}

.MenuItemCard .priceLabel {
  font-size: 0.85em;
  color: #7D7769;
  padding-right: 4px;
}

.MenuItemCard:focus .edit, .MenuItemCard:focus .remove, .MenuItemCard.premade:focus .remove {
  transform: translateX(0);
}
.MenuItemCard .edit, .MenuItemCard .remove {
  display: block;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transform: translateX(140px);
}
.MenuItemCard.premade .remove {
  transform: translateX(70px);
}
.MenuItemCard .edit button, .MenuItemCard .remove button {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0;
}
.MenuItemCard .edit button img, .MenuItemCard .remove button img {
  position: absolute;
  top: 0;
  left: 0;
  right: 1px;
  width: 69px;
}
.MenuItemCard .edit {
  right: 70px;
  border-right: 1px solid #F2EDE9;
  background-color: #8fb291;
}
.MenuItemCard .remove {
  background-color: #e39e9f;
}
.MenuItemCard img {
  width: 100%;
  height: 70px;
}

.MenuItemCard > li:first-child {
  width: 70px;
  display: block;
  position: relative;
  background-color: #ffffff;
}
.MenuItemCard > .body {
  padding-left: 10px;
  padding-bottom: 23px;

}
.MenuItemCard li h5 {
  font-size: 1em;
  margin-bottom: 0;
  line-height: 1.6em;
  margin-top: 8px;
}
.MenuItemCard .body {
  width: calc(100% - 70px);
  position: relative;
}
.MenuItemCard .EditIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 0.75em;
  color: #91B698;
}
.MenuItemCard p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.MenuItemCard > .body p {
  font-size: 0.8em;
  font-style: italic;
  margin: 0;
  line-height: 1.4em;
  display: inline-block;
}
.MenuItemCard .details {
  position: absolute;
  right: 10px;
  bottom: 0px;
  left: 10px;
}
.MenuItemCard .details > p:nth-child(1) {
  max-width: 50%;
  margin-right: 10px;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .cart .MenuItemCard button {
    width: 70px;
  }
}
