.CartPriceLine {
  margin-top: 35px;
  width: 100%;
  display: flex;
}

.CartPriceLineTitle .Button {
  display: inline-block;
  margin-left: 5px;
  color: #90B797;
}

.CartPriceLine li {
  display: inline-block;
  vertical-align: text-top;
  font-size: 1.1em;
  padding: 0;
  margin: 0;
}

.CartPriceLine li:first-child {
  text-align: left;
  flex: 1;
  margin-left: 16px;
}

.CartPriceLine li:first-child p {
  margin-bottom: 6px;
}

.CartPriceLine li:last-child {
  text-align: right;
  margin-right: 16px;
}

.CartPriceLine p.count-gst {
  font-size: 0.85em;
  color: #91B698;
}