.img-menud {
  background-color: #ffffff;
  width: 100%;
  height: calc(100vh - 331px);
  min-height: 250px;
  text-align: center;
  overflow-x: hidden;
}

.PersonalizeForm .Select {
  width: 100%;
  color: #7D7769;
}

.Personalize .MenuContentFrame {
  padding-bottom: 45px;
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .PersonalizeInfo {
    max-width: 600px;
    margin: auto;
  }
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .PersonalizeInfo {
    max-width: 600px;
    margin: auto;
  }
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .Personalize .MenuContentFrame {
    padding-bottom: 80px;
  }
}
