.Input {
  padding: 12px !important;
  background-color: transparent !important;
  border: 1px solid #91B698 !important;
  border-radius: 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #3c6142;
  text-align: left;
}

.Input::placeholder {
  color: #91B698;
}

.Input.hasError {
  border: 1px solid #D85A5B !important;
}

.Input.hasError,
.Input.hasError::placeholder {
  color: #D85A5B;
}

.Input:focus {
  outline: none;
}

.Input span.caret {
  color: #91B698;
  position: absolute;
  right: 20px;
  top: 20px;
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}