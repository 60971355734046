.EditFavorite .modal-dialog {
  width: 420px;
}
.EditFavorite.modal .modal-dialog .modal-body {
  padding: 25px;
}
.EditFavorite .modal-content {
  background-color: #FAFAF5;
}
.EditFavorite .modal-content p {
  padding-bottom: 6px;
}
.EditFavorite .modal-body>ul {
  margin-bottom: 15px;
}
.EditFavorite .modal-input {
  margin-bottom: 20px;
  width: 95%;
  margin-top: 7px;
}
.EditFavorite .modal-body>.Button {
  width: 100%;
}

.EditFavorite .modal-body>.Button.blue-link {
  margin-top: 5px;
  padding: 10px 0;
}

@media (max-width: 767px) {
  .EditFavorite .modal-dialog {
    width: 100vw;
    margin: 50px 0 0 0;
  }
  .EditFavorite .modal-content {
    max-width: 360px !important;
  }
}
