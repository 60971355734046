.PastOrderHeader {
  width: 100%;
  display: flex;
}

.PastOrderHeader .Body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.PastOrderHeader .Details {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.PastOrderHeader .Body .Date {
  color: #958F88;
  font-size: 0.8em;
  margin-bottom: 4px;
}

.PastOrderHeader .Body .GglocationName {
  font-size: 0.95em;
  color: #3C6142;
}

.PastOrderHeader .Body .GglocationName .OrderNumber {
  color: #958F88;
}

.PastOrderHeader .Price {
  font-size: 1em;
  color: #3C6142;
}

.PastOrderHeader .Price .DollarSign {
  font-size: 0.8em;
  margin-right: 4px;
}

.PastOrderHeader .Price .fa {
  margin-left: 8px;
  vertical-align: text-bottom;
  color: #91B698;
}

.PastOrderHeader .Price a {
  display: initial;
}

.PastOrderHeader .OrderStatusLabel {
  flex: 1;
  font-size: 14px;
  color: #FFF;
}

.PastOrderHeader .OrderStatusLabel span {
  padding: 2px 5px;
  border-radius: 3px;
}

.PastOrderHeader .OrderStatusLabel.Pending {
  background-color: #92D27F;
}

.PastOrderHeader .OrderStatusLabel.Cancelled {
  background-color: #8CA1A5;
}

.PastOrderHeader .OrderStatusLabel.Ready {
  background-color: #3C6142;
}

.PastOrder .PastOrderItems {
  font-size: 0.95em;
  color: #3C6142;
}

.PastOrder .PastOrderItems ul {
  display: flex;
}

.PastOrder .PastOrderItems ul li:nth-child(1) {
  width: 76%;
}

.PastOrder .PastOrderItems ul li:nth-child(2) {
  width: 12%;
  text-align: right;
}

.PastOrder .PastOrderItems ul li:nth-child(3) {
  width: 12%;
  text-align: right;
}

.PastOrderFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px -15px -15px -15px;
  padding: 15px;
  border-top: solid 1px #ddd;
}

.PastOrderFooter .Button.btn-primary {
  padding: 8px 24px;
  float: right;
}
