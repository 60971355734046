.CyoBody {
  max-height: calc(100vh - 45px - 54px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 80px;
}

.CyoIngredientsPicker.isHeaderShown .CyoBody {
  max-height: calc(100vh - 45px - 54px - 74px);
}


/* Tablet Only */
@media (min-width: 768px) and (max-width: 992px) {
  .CyoIngredientsPicker.CyoBody {
    max-height: calc(100vh - 45px - 54px - 25vh);
  }

  .CyoIngredientsPicker.isHeaderShown .CyoBody {
    max-height: calc(100vh - 45px - 54px - 69px - 25vh);
  }
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .CyoIngredientsPicker.isHeaderShown .CyoBody {
    max-height: calc(100vh - 45px - 54px - 54px);
  }

  /*
    iOS devices only.
    100vh on iOS is not equivalent to the full available viewport, instead it includes 75px high
    navigation bars on both sides.
  */
  @supports (-webkit-overflow-scrolling: touch) {
    .App:not(.isWebView) .CyoIngredientsPicker.isHeaderShown .CyoBody {
      max-height: calc(100vh - 45px - 54px - 54px - 75px);
    }
  }
}
