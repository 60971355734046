.PaymentMethod {
  display: flex;
  text-align: left;
  margin-top: 10px;
  align-content: center;
  padding: 0 10px 0 10px;
}

.PaymentMethod img {
  width: auto;
  height: 17px;
  margin-right: 10px;
}

.PaymentMethod span {
  vertical-align: middle;
}

.PaymentMethod .PaymentMethodSelect {
  vertical-align: top;
  font-size: 0.85em;
  padding: 0;
  line-height: inherit;
  border: none;
}

.PaymentMethod .PaymentMethodSelect:hover {
  cursor: pointer;
}

.PaymentMethod .PaymentMethodSelect:focus {
  outline: none;
}

.PaymentMethod .SelectWrapper {
  flex: 1;
}

.PaymentMethod .SelectWrapper::after {
  font-family: 'Sentinel A', 'Sentinel B', serif;
  content: 'Change';
  color: #0092AC;
  font-size: inherit;
  top: 0;
  right: 0;
}

.PaymentMethod .SelectWrapper:hover::after {
  text-decoration: underline;
}

.PaymentMethod .PaymentMethodSelect.isPaymentMethodSelected {
  letter-spacing: 3px;
}

.PaymentMethod .Button {
  float: right;
}
