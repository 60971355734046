.Select {
  background: transparent;
  appearance: none;
  padding: 0 20px;
  line-height: 46px;
  height: 100%;
  width: 100%;
  border-color: #91B698;
  border-radius: 4px;
  font-size: 14px;
}

.SelectWrapper {
  position: relative;
  height: 100%;
}

.SelectWrapper::after {
  position: absolute;
  top: 15px;
  right: 20px;
  content: "\F0D7";
  font-family: 'FontAwesome';
  font-size: 20px;
  color: #91B698;
  pointer-events: none;
}

.Select.gray {
  border-color: #E2DFD8;
}
