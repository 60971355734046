.GglocationName li img {
  width: 24px;
  height: auto;
  margin-right: 8px;
  position: relative;
}

.GglocationName li .Name {
  vertical-align: middle;
}
