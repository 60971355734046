.DietConflictResolver .modal-dialog {
  width: 420px;
}
.DietConflictResolver .modal-body {
  padding: 25px;
}
.DietConflictResolver ul.diet-item-list {
  margin-top: 15px;
}
.DietConflictResolver ul.diet-item {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: #FAF7F4;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);
  text-align: left;
  margin-bottom: 12px;
}
.DietConflictResolver ul.diet-item li:first-child {
  width: 90px;
  background-color: #ffffff;
}
.DietConflictResolver ul.diet-item li img {
  width: 90px;
  height: auto;
}
.DietConflictResolver ul.diet-item li:nth-child(2) {
  width: calc(100% - 90px);
  padding: 10px;
}
 .DietConflictResolver ul.diet-item h5 {
  font-size: 1.1em;
  line-height: 1.6em;
  margin-bottom: 1px;
  margin-top: 10px;
}

.DietConflictResolver ul.diet-item p{
  color: #7D7769;
  font-size: 0.95em;
}
.DietConflictResolver ul.diet-item li:last-child {
  width: 66x;
}
.DietConflictResolver ul.diet-item li:last-child button {
  width: 100%;
  height: 100%;
  color: #0191AB;
}
.DietConflictResolver ul.button {
  display: flex;
}
.DietConflictResolver ul.button li {
  flex: 1;
  margin: 15px 5px 0 5px;
}
.DietConflictResolver ul.button li button {
  padding: 12px !important;
  height: 100%;
  width: 100%;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px){
  .DietConflictResolver .modal-dialog {
    max-width: 360px !important;
    width: 90%;
  }
  .DietConflictResolver .modal-content {
    margin: 30px 0 20px;
  }
}
