.SignIn input {
  padding: 12px;
  background-color: transparent !important;
  border: 1px solid #91B698 !important;
  border-radius: 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #3c6142;
  text-align: left;
  width: 100%;
  margin-bottom: 14px;
}

.SignIn .input-alert input {
  border: 1px solid #D55157 !important;
  color: #D55157;
}

.SignIn .input-alert p.input-label {
  color: #D55157;
}

.SignIn ::-webkit-input-placeholder {
  text-align: left;
  font-style: italic;
  color: #8FB498;
  position: relative;
  left: 0px;
}

.SignIn ::-moz-placeholder {
  text-align: left;
  font-style: italic;
  color: #8FB498;
  position: relative;
  left: 0px;
}

.SignIn ::-ms-input-placeholder {
  text-align: left;
  font-style: italic;
  color: #8FB498;
  position: relative;
  left: 0px;
}

.SignIn ::-moz-placeholder {
  text-align: left;
  font-style: italic;
  color: #8FB498;
  position: relative;
  left: 0px;
}
.SignIn {
  text-align: center;
  margin: auto;
  width: 380px;
  color: #3c6142;
  margin-top: 30px;
}
.SignIn h4 {
  margin-bottom: 20px;
}
.SignIn>p {
  width: 70%;
  margin: auto;
  margin-bottom: 15px;
}
.SignIn .longer-text {
  width: 150%;
  margin: 0 auto 35px -25%;
}
.SignIn .middle-text {
  width: 100%;
  margin: 0 auto 35px auto;
}

.SignIn p strong {
  font-weight: 600;
}
.SignIn p .blue-link span {
  font-weight: normal;
}
.SignIn .input-alert-text {
  color: #D55157;
  margin-top: -10px;
  margin-bottom: 20px;
}
.SignIn .input-alert-text span {
  font-size: 1.4em;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.SignIn .input-alert-text {
  color: #D55157;
  margin-top: -10px;
  margin-bottom: 20px;
}
.SignIn .input-alert-text span {
  font-size: 1.4em;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.SignIn img:not(.PaymentIcon) {
  width: 50%;
}
.SignIn p.input-label {
  font-size: 0.95em;
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
}
.SignIn .btn-primary {
  width: 100%;
}

p.user-email::before {
  background: url(input-icon-envelope-green.svg) no-repeat 60% 50%;
  background-size: 50%;
  padding-left: 34px !important;
  content: "";
  margin-left: -16px;
}
.SignIn ul.name {
  position: relative;
  padding-bottom: 85px;
}
.SignIn ul.name li {
  display: inline-block;
  width: 49%;
}
.SignIn ul.name li:first-child {
  position: absolute;
  left: 0;
}
.SignIn ul.name li:last-child {
  position: absolute;
  right: 0;
}

.SignIn ul.payment-icon li {
  display: inline-block;
  margin: 12px 6px 8px 6px;
}
.SignIn ul.payment-icon li img {
  width: 34px;
  height: auto;
}
.SignIn ul.save-card-check {
  margin: 25px auto;
  text-align: center;
}
.SignIn ul.save-card-check li {
  display: inline-block;
}
.SignIn ul.save-card-check li:nth-child(2) {
  padding-left: 5px;
  position: relative;
  top: 3px;
}
.SignIn .btn-short {
  max-width: 300px;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .SignIn {
    width: 86%;
    max-width: 380px;
  }
  .SignIn p.user-email {
    text-align: left;
    width: 100%;
    padding-left: 8px;
    font-style: italic;
  }
}
