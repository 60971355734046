/* #map */
.Map {
  position: relative;
  width: 63vw;
  height: calc(100vh - 45px);
  border-right: 1px solid #ffffff;
  float: left;
}

.MapElement {
  position: absolute;
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
