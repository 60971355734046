.GglocationsSelect {
  margin-top: 5px;
  margin-bottom: 10px;
  max-width: 400px;
  margin: 5px auto 10px;
}
.GglocationsSelect select {
  width: 100%;
}
.StoreIcon {
  width: 17px;
  height: auto;
  margin-right: 3px
}

.GglocationsSelect .OtherStores {
  border-top: solid 1px #EEE;
}
