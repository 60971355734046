.modal-small .modal-content p.icon {
  color: #E5AA44;
  font-size: 3.4em;
  margin-bottom: 6px !important;
}
.modal-small .modal-content p.icon span {
  font-weight: 400 !important;
}
.icon-danger {
  color: #D55157 !important;
}
